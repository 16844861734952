.c-sale-banner {
	display: flex;
	background-color: $color-default-decimal;
	border: 1px solid rgba($color-default-sextuple, 0.06);
	border-radius: 4px;
	width: auto;
	margin: 0 10px;
	min-height: 100px;
	padding: 10px 15px;

	&__image{
		display: flex;
		background-size: cover;
		background-position: center;
		height: 83px;
		width: 83px;
		margin-right: 56px;
	}

	&__preheader,
	&__text {
		font-size: 14px;
		line-height: 17px;
		font-weight: 300;
		opacity: 0.7;
	}

	&__header {
		font-size: 16px;
		line-height: 19px;
		font-family: $medium;
		margin: 7px 0;
		strong {
			color: $color-highlight;
		}
	}
}

@media (max-width:1199px) {
	.c-sale-banner {
		&__image{
			min-width: 70px;
			height: 70px;
			margin-right: 40px;
		}
	}
}

@media (max-width:991px) {
	.c-sale-banner {
		padding-left: 10px;
		&__image{
			margin-right: 20px;
		}
	}
}

@media (max-width:480px) {
	.c-sale-banner {
		&__image{
			margin-right: 10px;
		}
	}
}