.close {
  z-index: 5;
  cursor: pointer;
  transition: all 0.3s;
  @include width_height(14px);
  top: 15px;
  right: 15px;
  position: absolute;
  transform: rotate(45deg);
  &:hover {
    transform: scale(1.1) rotate(45deg);
  }
  &::before,
  &:after {
    content: '';
    transition: all 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100px;
    background: $color-default-secondary;
  }
  &::before {
    width: 100%;
    height: 2px;
  }
  &:after {
    width: 2px;
    height: 100%;
  }
  &:hover {
    transform: scale(1.1) rotate(45deg);
  }
  // МОДИФИКАЦИЯ КРЕСТИКА
  &--primary {
    @include width_height(18px);
  }
  &--secondary {
    @include width_height(15px);
    &::before,
    &:after {
      background: $color-default-sextuple;
    }
    &::before {
      height: 1px;
    }
    &:after {
      width: 1px;
    }
    &:hover {
      &::before,
      &:after {
        background: $color-default-sextuple;
      }
    }
  }
  // МОДИФИКАЦИЯ ПОЗИЦИИ
  &--position-primary {
    top: 50%;
    right: 0;
    transform: translate(0%, -50%) rotate(45deg);
    &:hover {
      transform: translate(0%, -50%) scale(1.1) rotate(45deg);
    }
  }
  &--position-secondary {
    top: 50%;
    right: 40px;
    transform: translate(0%, -50%) rotate(45deg);
    &:hover {
      transform: translate(0%, -50%) scale(1.1) rotate(45deg);
    }
  }
}

.close_small {
  @include width_height(10px);
  top: 50%;
  right: 6px;
  transform: translate(0%, -50%) rotate(45deg);
  &:hover {
    transform: translate(0%, -50%) scale(1.1) rotate(45deg);
  }
  &::before,
  &:after {
    background: $color-default-sextuple;
  }
}

.close_tags {
  position: absolute;
  left: -29px;
  top: 5px;
  transform: rotate(-45deg);
  height: 24px;
  width: 45px;
  background: transparent;
}

.close_basket {
  @include width_height(15px);
  top: 50%;
  right: 40px;
  transform: translate(0%, -50%) rotate(45deg);
  &::before,
  &:after {
    background: $color-default-sextuple;
  }
  &::before {
    height: 1px;
  }
  &:after {
    width: 1px;
  }
  &:hover {
    transform: translate(0%, -50%) scale(1.1) rotate(45deg);
    &::before,
    &:after {
      background: $color-default-sextuple;
    }
  }
}

.close_cart {
  position: absolute;
  @include width_height(12px);
  top: 19px;
  right: 7px;
  background: url(./../../images/icons/icon-basket-delete.svg) no-repeat
    center/cover;
  cursor: pointer;
  transition: all 0.3s;
}

.close_menu {
  @extend .close_basket;
  right: 15px;
}

// NEW STYLE

.c-close {
  @include width_height(16px);
  border-radius: 100%;
  display: block;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  &--circle {
    transform: rotate(-45deg);
    &::before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background: $color-danger;
      border-radius: 5px;
      display: block;
      transform: translate(-50%, -50%);
    }
    &::before {
      width: 75%;
      height: 2px;
      // transform:translate(-50%,-50%) rotate(-45deg);
    }
    &:after {
      width: 2px;
      height: 75%;
      // transform:translate(-50%,-50%) rotate(45deg);
    }
    &-color--danger {
      border: 2px solid $color-danger;
    }
    &:hover {
      transform: rotate(-45deg) scale(1.05);
    }
  }
  &--normal {
    transform: rotate(-45deg);
    &::before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background: $color-default-primary;
      border-radius: 5px;
      display: block;
      transform: translate(-50%, -50%);
    }
    &::before {
      width: 100%;
      height: 2px;
    }
    &:after {
      width: 2px;
      height: 100%;
    }
    &:hover {
      transform: rotate(-45deg) scale(1.05);
    }
  }

  &--color {
    &::before,
    &:after {
      background: $color-default-quaternary;
    }
    &--primary {
      &::before,
      &:after {
        background: $color-default-sextuple;
      }
    }
  }

  &--width {
    @include width_height(14px);
  }
}
