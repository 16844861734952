.c-contacts-primary{
	max-width: 950px;
	margin: 0 auto;
	

	&--padding-primary{
		padding: 70px 0;
	}
}

.c-contacts-primary__row{
	margin: -15px -30px;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap; 
}  



.c-contacts-primary__col{
	width: 50%;
	padding: 15px 30px;
}
.c-contacts-primary__item{
	display: flex;
	
	width: 100%;
}

.c-contacts-primary__content{
	padding-top: 10px;
	padding-left: 20px;
	width: calc(100% - 180px);
}
.c-contacts-primary__image{
	width: 180px;
	height: 180px;
	position: relative;
}

.c-contacts-primary__list-item{
	padding-left: 30px;
	margin-bottom: 15px;
	position: relative;
	svg{
		left: 0;
		top: 2px;
		position: absolute;
		width: 16px;
		height: 16px;
		object-fit:contain;
	}
	&:last-child{
		margin-bottom: 0;
	}
}
.c-contacts-primary__link{
	// color: $color-primary;
	// & + svg path{fill:$color-primary;}
}

.c-contacts-primary__list-primary{ 
	li{
		display: flex;
        justify-content: space-between;
	}
}

@media (max-width:991px) {
	.c-contacts-primary__row{
		margin: -15px;
	}
	.c-contacts-primary__col{
		padding: 15px;
	}
}
@media (max-width:991px) and (min-width:731px) {

	.c-contacts-primary__image{
		width: 100px;
		height: 100px;
	}
	.c-contacts-primary__content{
		width: calc(100% - 100px);
		font-size: 14px;
		background: red;
	}
}
@media (max-width: 730px) {
	.c-contacts-primary__col{
		width: 100%;
	}
}

@media (max-width: 530px) {
	.c-contacts-primary__image{
		width: 80px;
		height: 80px;
	}
	.c-contacts-primary__content{

		width: calc(100% - 80px);
		max-width: 250px;
		font-size: 14px;
	}
}