.sidebar-mobile,
.mobile-search {
  display: none;
}

@media (max-width: 1199px) {
  %padding-sidebar-mobile {
    padding: 10px 15px;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: $color-default-primary;
  }

  .sidebar-mobile {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 150px);
    position: fixed;
    left: 0;
    bottom: 0;
    background: $color-default-secondary;
    transform: translateY(100%);
    transition: all 0.5s;
    z-index: 160;
    &__head {
      width: 100%;
      @extend %padding-sidebar-mobile;
      padding: 10px 45px 10px 15px;

      &.mobile-search {
        padding-right: 15px;
        svg {
          left: 20px;
        }
        .head-form__input {
          height: 50px;
          border-radius: 25px;
          padding-left: 10px;
        }
      }
      position: absolute;
      z-index: 50;
      top: 50px;
      height: 52px;
    }

    &__head.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &__head.active {
      opacity: 1;
      pointer-events: all;
      z-index: 9999;
    }

    &__content {
      height: calc(100% - 75px);

      &.no-social {
        height: calc(100% - 50px);
      }

      padding-top: 0;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      overflow-y: scroll;

      &-top {
        padding-top: 18px;
        background: $color-default-primary;
      }
      &-bottom {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        //height: 100%;
        min-height: 255px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);

        padding: 45px 35px;
      }

      &-contacts {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //overflow: hidden;
        margin-top: 30px;
        .menu-info__number {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 11px;
        }

        .sidebar-mobile__list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            font-size: 16px;
            margin-bottom: 6px;
          }
          &-item {
            padding: 0;
          }
          .select-option__choice--arrow {
            padding-left: 20px;
            padding-right: 0;
            &::after {
              left: 0;
            }
          }
        }
      }
    }

    &__footer {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      @extend %padding-sidebar-mobile;

      padding: 0;
      height: 75px;

      .menu-info {
        width: 100%;
        height: 100%;
      }

      .menu-info__items {
        padding: 0;
        height: 100%;
      }

      .menu-info__list {
        display: flex;
        height: 100%;
        justify-content: space-between;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          border-right: 1px solid $color-default-nonary;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            padding: 0;
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .sidebar-mobile__list {
    display: flex;
    align-items: center;
  }
  .sidebar-mobile__list-item {
    padding: 15px 15px;
  }

  body.js-body-sidebar-menu {
    overflow: hidden;
    .sidebar-mobile {
      transform: translateY(0px);
    }
  }
}
