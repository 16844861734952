.goods {
  background: $color-default-secondary;
  padding: 30px 0 30px;
}

.goods__wrapp {
  display: flex;
}

.goods__col {
  &--primary {
    width: 42%;
  }
  &--secondary {
    padding: 0 5px 0 20px;
    width: 49%;
  }
  &--tertiary {
    width: 9%;
  }
}

.goods__edge {
  text-align: center;
  width: 100%;
  padding: 20px 0 0 0px;
}

.goods__visual {
  margin-bottom: 30px;
}

.goods__description {
  position: relative;
  &--primary {
    padding-left: 45px;
    &::before {
      content: '';
      @include width_height(25px);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      background: url(./../../images/icons/icon-info.svg) no-repeat
        center/contain;
    }
  }
  &--mb {
    margin-bottom: 50px;
  }
}

.goods__description-text {
  font-family: $light;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 8px;
}

.goods__info {
  background: $color-default-primary;
  padding: 20px 25px 35px 30px;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  position: relative;

  &--sale {
    &:after {
      content: attr(data-sale);
      position: absolute;
      top: 15px;
      right: 15px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      padding: 5px 7px;
      background: #ffd8dd;
      border-radius: 100px;
      font-size: 12px;
      color: #ed4545;
    }
  }
}

.goods__btn-back {
  font-family: $light;
  color: $color-default-quinary;
  position: relative;
  transition: 0.3s;
  &::before {
    content: '';
    position: absolute;
    transition: 0.3s;
    top: 50%;
    left: -10px;
    padding: 4.5px;
    border: solid $color-default-quinary;
    border-width: 0 1px 1px 0;
    transform: translate(0%, -50%) rotate(135deg);
  }
  &:hover {
    &::before {
      left: -18px;
    }
  }
}

.goods__title {
  font-size: 32px;
  color: $color-default-quinary;
  line-height: 30px;
  display: block;
}

.goods__desc {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 20px 0;
}

.goods__class {
  font-family: $light;
  font-size: 16px;
  display: flex;
  svg {
    margin-right: 4px;
    // path{
    // 	fill: $color-secondary;
    // }
  }
  h5 {
    font-weight: 300;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    span {
      // color: $color-secondary;
      font-family: $bold;
    }
  }
}

.goods__class-link {
  color: rgba(47, 23, 1, 0.8);
  &:nth-child(2) {
    margin-left: 7px;
  }
}

.goods__info-text {
  font-size: 14px;
  display: block;
}

.goods__info-text_width {
  width: 238px;
}

.goods__info-text_mobile-sise {
  padding-right: 45px;
}

.goods__info-text_m2 {
  margin: 25px 0 15px 0;
}

.goods__info-text_mr15 {
  margin-right: 15px;
}

.goods__size,
.goods__color-flower,
.goods__size-price,
.goods__fixed-wrapp {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.goods__size-radio-help,
.goods__size-price,
.goods__fixed-wrapp {
  // width: calc(100% - 200px);
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.goods__pack {
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;
  margin: 5px 0;
}

.goods__size-price {
  padding: 40px 0 40px 0;
}

.color-pack__icon {
}

.goods__color-flower {
}

.color-flower__icons {
  position: relative;
}

.goods__addition {
}

.goods__price {
  font-family: $light;
  font-size: 26px;
}

.goods__price-text {
  color: $color-default-quinary;
  display: inline-block;
}

// SALE

.goods__price-sale {
  font-family: $regular;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  white-space: nowrap;
}

.goods__block {
}

.goods__parameters {
  background: $color-default-primary;
  border: 1px solid #e1e1e1;
  padding: 20px 0 20px 30px;
  background: #fff;
  border-radius: 15px;
  margin-top: 20px;
}

.goods__parameters-title {
  padding-bottom: 20px;
  display: block;
}

.view-item__video {
  padding-top: 100%;
  position: relative;
  .ytb-video-wrap {
    cursor: pointer;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }
  .ytb-video-container {
    height: 100%;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .ytb-video-play-button {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ytb-video-iframe {
    width: 100%;
    height: 100%;
  }
}

.goods__complect {
  height: 93px;
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-top: 30px;
  background-color: $color-default-primary;
  box-shadow: 0px 20px 40px -10px rgba($color-default-sextuple, 0.2);

  &-cover {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 90px;
    min-width: 90px;
    background: $color-highlight-primary;
    position: relative;
    padding: 5px;

    &-text {
      color: $color-default-primary;
      font-size: 11px;
      font-family: $regular;
      text-transform: uppercase;
      position: relative;
      z-index: 110;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      height: 80px;
      width: 150px;
      background: url('./../../images/views/blocks/c-complect/cover.png') center
        no-repeat;
      z-index: 100;
    }
  }

  &-list {
    display: flex;
    padding-left: 80px;
    height: 100%;
    width: auto;
    position: relative;
    z-index: 150;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    width: 70px;
    cursor: pointer;

    &-image {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      margin-bottom: 8px;
    }
    &-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      height: 30px;
      transition: 0.3s;
    }
  }
}

.goods__ask-florist {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 8px;
}

@media (max-width: 1199px) {
  .goods__title {
    font-size: 28px;
  }
  .goods__price-text {
    font-size: 20px;
  }
  .goods__price-sale {
    font-size: 16px;
    margin-left: 5px;
  }
  .goods__parameters {
    padding: 20px 0 20px 30px;
    margin-top: 20px;
  }
  // .goods__pack{
  // 	-webkit-align-items: center;
  // 	        align-items: center;
  // }
  .goods__amount {
    padding: 0 15px;
  }
  .goods__info-text_width {
    width: 105px;
  }
  .goods__info-text_mobile-sise {
    padding-right: 25px;
  }
}
@media (max-width: 991px) {
  .goods {
    background: $color-default-primary;
    padding: 30px 0 0 0;
  }
  .goods__wrapp {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .goods__col {
    width: 100%;
    &--secondary {
      padding: 0 0px 0 0px;
    }

    &--tertiary {
      padding: 0 0 30px 0;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 100vw;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        height: 100%;
        background: $color-default-secondary;
        position: absolute;
      }
      .goods__edge,
      .goods__description,
      .field-select {
        position: relative;
        z-index: 3;
      }
    }
  }

  .goods__info-text_width {
    width: 225px;
  }
  .goods__info {
    border: none;
    padding: 0px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    &:after {
      top: 5px;
    }
  }
  .goods__title {
    font-size: 32px;
  }
  .goods__size-price {
    padding: 0px 0 15px;
    order: -1;
  }
  .goods__size {
    -webkit-order: -1;
    -ms-order: -1;
    order: -1;
  }
  .goods__parameters {
    display: none;
  }
  .goods__edge {
    width: 100%;
    padding: 30px 0 0 0px;
  }

  .goods__btn-back {
    display: none;
  }

  .goods__description {
    padding: 0px 0 30px;
    &--primary {
      padding: 30px 0 30px 45px;
    }
    &--mb {
      margin-bottom: 0;
    }
  }

  .goods__desc {
    .goods__description--primary {
      display: none;
    }

    .goods__description-info {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 730px) {
  .goods__visual {
    margin-bottom: 20px;
  }

  .goods__info-text_width {
    width: 105px;
  }

  .goods__fixed {
    background: $color-default-primary;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 150;
    box-shadow: 0 -2px 8px rgba($color-default-sextuple, 0.1);

    &-content {
      display: flex;
      align-items: center;
      max-width: 480px;
      margin: 0 auto;
      width: 100%;
      padding: 10px;
    }
  }
  .goods__fixed-wrapp {
    width: 100%;
    padding: 10px;
  }
  .goods__fixed-wrapp {
    .goods__info-text {
      display: none;
    }
  }

  .goods__ask-florist {
    margin-top: 0;
    width: unset;
    &-btn {
      text-indent: -9999px;
      border: none !important;
      height: 24px;
      width: 24px;
      margin-left: 20px;
      margin-right: 15px;
      padding: 0;
      background-position: center;
    }
  }

  .goods__pack {
    align-items: center;

    &-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .goods__pack .goods__description {
    position: relative;
    height: 25px;
    width: 25px;
    display: flex !important;
    align-items: center;
    margin-bottom: 4px;

    &-text span,
    &-info {
      display: none !important;
    }

    .c-link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-indent: -9999px;
    }

    &--primary {
      padding: 0px 0 25px 25px;
    }
  }
}

@media (max-width: 530px) {
  .goods__price {
    max-width: 107px;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .goods__price-text {
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
  }

  .goods__amount {
    padding: 0 15px 0 0;
  }
  .goods__info-text_mobile-sise {
    padding-right: 10px;
  }

  .goods__class_mobile {
    width: 100%;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .goods {
    padding-top: 0;
  }
  .goods__visual {
    width: 100vw;
    position: relative;
    left: -10px;
  }
  .goods__info:after {
    right: 0;
  }
  .goods__title {
    font-size: 22px;
  }
  .goods__size-price {
    justify-content: flex-start;
    .goods__price-text {
      font-weight: 700;
    }
  }

  .goods__complect {
    height: 113px;
    width: 100vw;
    position: relative;
    left: -10px;
    &-cover {
      width: 57px;
      min-width: 57px;
      align-items: flex-start;
      &:after {
        height: 51px;
        width: 95px;
        background-size: 100% auto;
        top: unset;
        bottom: 19px;
      }

      &-text {
        position: absolute;
        color: $color-rare;
        left: 109px;
        top: 6px;
        width: 83px;
      }
    }
    &-list {
      padding-left: 10px;
      padding-top: 30px;
      width: 100%;
      justify-content: center;
    }
    &-item {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 380px) {
  .goods__class_mobile {
    margin-top: 20px;
  }
}

@media (max-width: 350px) {
}
