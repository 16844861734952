.our-services{
	background: #FFF;
	padding: 0px 0 100px 0;
}

.our-services__wrapp{
	padding-top: 85px;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.our-services__info{
	text-align: left;
	max-width: 350px;
}

.our-services__image{
	max-width: 350px;
}

.our-services__text{
	padding-top: 20px;
	font-size: 16px;
	color: $color-default-quinary;
	max-width: 298px;
}


@media (max-width:991px) {
}

@media (max-width:730px) {
	.our-services{
		padding: 30px 0 70px 0;
	}
	.our-services__wrapp{
		padding-top: 25px;
	}

	.our-services__info{
		margin-bottom: 0px;
		text-align: center;
		-webkit-order: 2;
		    -ms-order: 2;
		        order: 2;
	}
	
}

