.head{
	box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.15);
	position: relative;
	z-index: 300;
	//transition: 0.3s;
	// @include media-breakpoint-up(lg) {
		// &.sticky{
			
		// }
		// &.active-move{
			
		// }
	// }
}
.sticky-spacer + .head{
	.c-billet-promo-code{
		order:1;
	}
	.head__wrapp{
		display:flex;
		flex-direction: column;
	}
	
	.head-bottom{
		order:2;
	}
}
.head__wrapp{
	
} 




.head__burger{
	@extend .block-mobile-basket;
}




@media (max-width:1199px) {
	.head {
		
	}
	.head-top{ 
		background: white;
	}

	body{
		&.js-body-sidebar-menu{
			.head{
				pointer-events: none;
				box-shadow: 0px 2px 50px rgba(0, 0, 0, 0);
			}
		}
	}

}