.block-mobile-basket{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	.icon-icon-cart{
		font-size: 28px;
		color: $color-default-sextuple;
	}
}

.block-mobile-basket__amount{
	font-size: 14px;
	color: $color-default-sextuple;
	padding-left: 5px;
}

.block-mobile-basket{
	@include width_height(50px);
	-webkit-justify-content: center;
	        justify-content: center;
	&:hover{
    	background: rgba($color-default-quaternary,0.1);
    }
}