.main{
	width: 100%;
	padding: 30px 0 120px 0;
	&--primary{
		padding: 0px 0 40px 0;
	}
	&--bg{
		background: $color-default-secondary;
	}

	&--cart-empty {
		.the-content {
			max-width: 100%;
		}
		img {
			width: 60%;
		}
	}
} 

.main__row{
	display: flex;
	margin: 0 -15px;
    flex-wrap: wrap;
}



// For Article



.main-article{
	padding: 35px 0 45px 0;
}



@media (max-width:991px) {

}

@media (max-width:730px) {
	.main__row{
		margin: 0 -5px;
	}
}

@media (max-width:530px) {
	.main{
		padding-top: 20px;
		padding-bottom: 100px;
	}
}

@media (max-width:380px) {
	
}