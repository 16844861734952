.table{
	text-align: center;
	width: 100%;
	margin: 0 auto;
	border-collapse: collapse;
	tr:not(:last-child){
		border-bottom: 1px solid lighten($color-default-quaternary,60%);
	}
}



.table__row{
	font-family: $light;
	font-weight: 300;
	font-size: 16px;
	color: #000000;
	text-align: left;
}

.table__row-line{
	
	
}

.table__col{
	
	padding: 30px 15px;
	position: relative;
	&:first-child{
		border-color: transparent;
		color: rgba($color-default-quinary, 0.8);
	}
	.number-plus,
	.number-minus{
		padding: 9px 0;
	}
}


.table__box{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.table__title{
	font-size: 18px;
	color: $color-default-quinary;
	position: relative;
	border-bottom: 1px solid rgba($color-default-quaternary, 0.25);
	padding: 0 15px 10px;

	&:nth-child(1){
		width: 5%;
	}
	&:nth-child(2){
		width: 35%;
	}
	&:nth-child(3){
		width: 15%;
	}
	&:nth-child(4){
		width: 25%;
	}
	&:nth-child(5){
		width: 20%;
	}
}
.table__img{
	width: 62px;
	height: 62px;
	object-fit:cover;
}
.table__text{
	padding-left: 10px;
}
.table__icon{
	width: 25px;
	height: 30px;
	position: relative;
	
	svg{
		width: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
		// path{
		// 	fill:$color-primary;
		// }
	}
}






// For LK


.table__title-pl0{
	padding-left: 0;
}

.table__title_border2{
	border-bottom: 2px solid rgba($color-default-quaternary, 0.25);
}

.table__col-pl0{
    padding: 30px 15px 30px 0;
}

@media (max-width:991px) {
	
}


@media (max-width:730px) {
	.table{
		display: none;
	}
	
	.content-info__total{
		display: none;
	}
}

@media (max-width:530px) {


}

@media (max-width:380px) {

}