/** Generated by FG **/
@font-face {
  font-family: 'Museo-Cyrl-300';
  src: url('./../../fonts/Museo-Cyrl-300.eot');
  src: local('☺'), url('./../../fonts/Museo-Cyrl-300.woff') format('woff'),
    url('./../../fonts/Museo-Cyrl-300.ttf') format('truetype'),
    url('./../../fonts/Museo-Cyrl-300.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/** Generated by FG **/
@font-face {
  font-family: 'Museo-Cyrl-500';
  src: url('./../../fonts/Museo-Cyrl-500.eot');
  src: local('☺'), url('./../../fonts/Museo-Cyrl-500.woff') format('woff'),
    url('./../../fonts/Museo-Cyrl-500.ttf') format('truetype'),
    url('./../../fonts/Museo-Cyrl-500.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/** Generated by FG **/
@font-face {
  font-family: 'Museo-Cyrl-700';
  src: url('./../../fonts/Museo-Cyrl-700.eot');
  src: local('☺'), url('./../../fonts/Museo-Cyrl-700.woff') format('woff'),
    url('./../../fonts/Museo-Cyrl-700.ttf') format('truetype'),
    url('./../../fonts/Museo-Cyrl-700.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
