
.h-hide{
	display: none;
}
.h-show{
	display: none;
}
@media (max-width:1199px) {
	.h-hide-primary{
		display: none;
	}
}
@media (max-width:991px) {
	.h-hide-secondary{
		display: none; 
	}
	.h-show-secondary{
		display: block;
	}
}
@media (max-width:730px) {
	.h-hide-tertiary{
		display: none;
	}
	.h-show-tertiary{
		display: block;
	}
}
@media (max-width:530px) {
	.h-hide-quaternary{
		display: none;
	}
	.h-show-quaternary{
		display: none;
	}
}