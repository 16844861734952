.denotation{

	&--pb-primary{
		padding: 40px 0 10px 0;
	}
	&--pb-secondary{
		padding: 40px 0 40px;
	}

	&--bg{
		&-primary{
			background: $color-default-secondary;
		}
	}

	&--none{
		display: none;
	}

}


.denotation__wrapp{
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 8;

	&--width-primary{
		width: calc(100% - 325px);
    	margin: 0 0 0 auto;
	}
	&--width-secondary{
		width: calc(100% - 273px);
    	margin: 0 0 0 auto;
	}
}



@media (max-width:991px) {

	.denotation__wrapp{
		&--width-primary{
			width: calc(100% - 230px);
		}
	}

}

@media (max-width:730px) {
	.denotation{
		&--mobile{
			&-bg{
				background: lighten($color-default-tertiary,7%);

			}
		}

		&--pb-primary{
			padding: 25px 0 10px 0;
		}
	}
	.denotation__wrapp{
		&--width-primary,
		&--width-secondary{
			width: 100%;
		}

	}
	.denotation__title_basket{
		padding-bottom: 0px;
	}
}


@media (max-width:380px) {
	.denotation__title-lk-data{
		font-size: 39px;
	}
}