ul {
	list-style-type: none;
}

p, h1, h2, h3, h4 {
	margin: 0;
	font-weight: 100;
}

a {
	text-decoration: none;
	outline: none;
}
input, textarea {
	background-color: rgba(255,255,255, 0);
	outline: none;
	text-decoration: none;
	border: none;
}
img{
	max-width: 100%;
}
:focus {
	outline: none;
}

input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type="time"] {
	padding-right: 15px;
}


//jivosite mobile menu
.js-body-sidebar-menu .__jivoMobileButton {
	display: none !important;
	opacity: 0 !important;
	pointer-events: none !important;
}