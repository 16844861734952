
strong,b{
	font-family: $medium;
}
.h-regular{
	font-family: $regular;
}
.h-medium{
	font-family: $medium;
}





.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }