

.gratitude{
	padding: 50px 0;
	text-align: center;
}

.gratitude__wrapp{
	max-width: 400px;
	margin: 0 auto;
	width: 100%;
}
.gratitude__title{
	margin-bottom: 40px;
}


.gratitude__text{
	margin-bottom: 30px;
}