.bouquet{
	padding: 50px 0 50px 0;
}

.bouquet_p-none{
	padding: 0;
}

.bouquet_gray{
	padding: 40px 0 60px;
	background: $color-default-secondary;
}

.bouquet_last{
	padding: 120px 0 75px 0;
}

.bouquet__title{
	margin-right: 25px;
}

.bouquet__box,.bouquet__tab{
	display: flex;
}

.bouquet__box{
	padding-top: 35px;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	li.ui-tabs-tab{
		a{
			border: 1px solid transparent;
			-webkit-border-radius: 50px;
			        border-radius: 50px;
		}
	}
	li.ui-tabs-active{
		a{
			border: 1px solid $color-default-tertiary;
		}
	}
}


.bouquet__tab-item{
	margin-right: 20px;
	a{
		display: block;
		font-family: $light;
		font-size: 18px;
		padding: 8px 20px;
		color: $color-default-quinary;
	}
}

.bouquet__wrapp{
	padding: 35px 0 0 0;
}


	
@media (max-width:1199px){
	.bouquet__slider{
		max-width: 920px;
		margin: 0 auto;
	}
	.bouquet__tab-item{
		&:first-child{
			margin-left: 0px;
		}
	}
	.bouquet__title{
		margin: 0 25px 30px 0;
	}

}
@media (max-width:991px){
	.bouquet_gray{
		padding: 40px 0 50px;
	}
	.bouquet_last{
		padding: 50px 0;
	}

	.bouquet__title{
		margin: 0 0px 30px 0;
	}

	.bouquet__wrapp{
		padding: 15px 0 0 0;
	}


	.bouquet__slider{
		max-width: 640px;
		margin: 0 auto;
	}
}

@media (max-width:730px){
	.bouquet__tab-item{
		margin-right: 5px;
	}
	.bouquet__slide{
		padding: 10px 5px;
	}
}

@media (max-width:580px){
	.bouquet__tab{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.bouquet__slide{
		padding: 10px 60px;
	}
	
}

@media (max-width:380px){
	.bouquet__tab-item{
		&:nth-child(2){
			margin-right: 0px;
		}
	}
	.bouquet__slide{
		padding: 20px 20px;
	}
}