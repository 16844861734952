.quote-primary{
	text-align: center;
	padding: 90px 0 90px;
	color: $color-default-quinary;
	// path{
	// 	fill:$color-secondary;
	// }
}
.quote-primary__wrapp{
	max-width: 815px;
	margin: 0 auto;
}

.quote-primary__text{
	padding-top: 25px;
	font-size: 28px;
	span{
		padding: 0 10px;
		// background: $color-secondary;
		color: #FFF;
	} 
}



@media (max-width:991px) {
	.quote-primary{
		padding: 70px 0;
	}
}

@media (max-width:480px) {
	.quote-primary{
		padding: 50px 0 40px;
	}
	.quote-primary__text{
		font-size: 20px;
	}

}