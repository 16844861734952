.radio {
  cursor: pointer;
}

.radio_mb-10 {
  margin-bottom: 10px;
  display: block;
}

.radio_ml-45 {
  margin-left: 45px;
}

.radio__radio {
  display: none;
}
.radio__radio:checked + .radio__nesting {
  .radio__circle {
    // background:$color-primary;
    // border-color: $color-primary;
    &::before {
      background: #fff;
    }
  }
}

.radio__nesting {
  font-size: 0;
}
.radio__circle,
.radio__text {
  display: inline-block;
  vertical-align: middle;
}
.radio__circle {
  background: $color-default-primary;
  border: 1px solid #e2dede;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  margin-right: 10px;
  transition: 0.3s;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    // background: $color-primary;
    z-index: 1;
    transition: 0.3s;
  }
  &:hover {
  }
}
.radio__text {
  font-family: $light;
  font-weight: 300;
  font-size: 16px;
}

.radio__square {
  background: transparent;
  border: 1px solid $color-default-quinary;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  margin-right: 10px;
}
.radio__square--circle {
  border-radius: 50px;
}

.radio__square-inside {
  font-size: 10px;
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
  vertical-align: middle;
}

.radio__radio:checked + .radio__nesting {
  // .radio__square{
  // 	// background: $color-primary;
  // 	// border-color: $color-primary;
  // }
  .radio__square-inside {
    color: $color-default-primary;
  }
}

.goods-radio__nesting {
  display: inline-block;
  vertical-align: middle;
}
