%position-r {
  position: relative;
  z-index: 4;
}

.list-parameters {
  @extend %position-r;
  display: none;
  max-width: 390px;
  width: 100%;
  background: $color-default-primary;
  border: 1px solid $color-default-secondary;
  margin: 0 auto;
  border-radius: 4px;
  padding: 0 0px 0 20px;
  min-height: 50px;
  margin-top: 30px;
}

.list-parameters__name {
  line-height: 50px;
  font-size: 22px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);

    padding: 4px;
    border: solid $color-default-quinary;
    transition: 0.3s;
    border-width: 0px 0px 1px 1px;
    right: 15px;
  }
}

.list-parameters__drop-list {
  padding: 10px 0 70px 0;
  display: none;
}

.list-parameters__drop-item {
  display: flex;
  padding-bottom: 20px;
}

.list-parameters__drop-text {
  padding-right: 10px;
}

@media (max-width: 991px) {
  .list-parameters {
    display: block;
  }
}

@media (max-width: 380px) {
  .list-parameters__drop-list {
    padding: 10px 0 30px 0;
  }
}
