.head-bottom{
	// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
	transition: 0.3s;
	background: $color-default-primary;
	// &.sticky{
	// 	@include media-breakpoint-up(lg) {
	// 		z-index: 49;
			
	// 		// box-shadow: 0 2px 50px rgba($color-default-sextuple,.15);
	// 		// position: fixed;
	// 		// width: 100%;
	// 		// top: 0;
	// 		// left: 0; 
	// 	}
	// }
}

.head-bottom__wrapp{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    -webkit-justify-content: space-between;
	            justify-content: space-between;
}








