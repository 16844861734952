.recommendation{
	padding: 95px 0 60px 0;
}
.recommendation__wrapp{
	padding: 50px 50px 25px 50px;
	background: $color-default-secondary;
	border: 1px solid #E1E1E1;
	border-radius: 15px;
}
.recommendation__row{
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	// margin: 0 -20px;
	width: 100%;
}
	
.recommendation__col{
	padding: 0 20px;
	width: 100%;
	&--h4{
		font-size: 22px;
		padding-bottom: 45px;
		display: block;
	}
	&:first-child{
		width: 70%;
	}
	&:last-child{
		width: 30%;
	}
}

.recommendation__title{
	font-size: 28px;
	text-transform: uppercase;
	text-align: center;
	padding-bottom: 14px;
	display: block;
}

@media (max-width:1199px) {
	.recommendation__wrapp{
		padding: 50px 0px 25px 20px;
	}
	.recommendation__col{
		&:first-child{
			width: 65%;
		}
		&:last-child{
			width: 35%;
		}
	}
}

@media (max-width:991px) {
	.recommendation{
		padding: 50px 0;
	}
	.recommendation__wrapp{
		padding: 50px 20px 25px 20px;
	}
	.recommendation__col{
		h4{
			text-align: center;
		}
		&:first-child{
			width: 100%;
			padding-bottom: 50px;
		}
		&:last-child{
			max-width: 600px;
			width: 100%;
			margin: 0 auto;
		}
	}
}

@media (max-width:730px) {
	
}

@media (max-width:530px) {
	.recommendation__col{
		padding: 0;
		&:last-child{
			padding: 0;
			text-align: center;
		}
	}

}

@media (max-width:380px) {


}


