.menu{
	display: flex;
}

.menu__items{
	padding: 0 15px;
}

.menu__link{
	font-weight: normal;
	font-size: 14px;
	text-transform: uppercase;
	color: $color-default-quinary;
	// @extend %s-link-hover;
}

@media (max-width:1199px) {
	.menu{
		display: block;
	}
	.menu__items{
		padding: 0;
	}
	.menu__link{
		padding: 15px;
		display: block;
		&:after{
			content: none;
		}
		&:hover{
			background: rgba($color-default-secondary, 0.7);
		}
		span{
			vertical-align: middle;
		}
	}


	.menu__items-authorization {
		.menu__link {
			background-color: $color-default-nonary;
			height: 50px;
			width: 260px;
			border-radius: 100px;
			margin: auto;
			text-transform: none;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;

			span:first-child {
				margin-right: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 17px;
					height: 20px;
				}
				
			}
		}
		
	}

	.menu--top {
		display: flex;
		flex-wrap: wrap;
		padding: 30px 40px;
		.menu__items {
			margin-right: 35px;
			margin-bottom: 20px;		
		}

		.menu__link {
			font-size: 20px;
			text-transform: none;
			padding: 0;
		}
	}

	.menu--bottom {
		display: flex;
		flex-wrap: wrap;
		.menu__items {
			margin-bottom: 10px;
			margin-right: 20px;			
		}
		.menu__link {
			background-color: $color-default-primary;
			border: 1px solid rgba(0, 0, 0, 0.09);
			padding: 5px 7px;
		}
	}
}

@media (max-width:380px) {
	.menu--top {
		padding: 30px 20px;
	}

	.menu--bottom {
		.menu__items {
			margin-bottom: 10px;
			margin-right: 10px;			
		}
	}
}