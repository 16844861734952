.input,
.textarea{
	background: #fff;
	border: 0.5px solid #BCBCBC;
	border-radius: 4px;
	height: 45px;
	display: block;
	color: $color-default-quinary;
	width: 100%;
	text-indent: 20px;
	font-size: 14px;
	font-family: $light;
	transition:0.5s;

	@include placeholder {
		font-size: 14px;
		color: $color-default-quinary;
	}

	&--width{
		max-width: 220px;
	}
}

.textarea{
	margin-bottom: 0;
	resize:none;
	height: 96px;
	width: 100%;
	padding-top: 15px;
	&--primary{
		height: calc(90px + 15px);
	}
}
