.phone-button {
  position: fixed;
  height: 72px;
  width: 72px;
  bottom: 70px;
  right: 15px;
  z-index: 100;
  display: block;
  transition: 0.7s;

  @media(min-width: 900px) {
    display: none;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.7s;
    animation: pulse 3s infinite;
    path {
      transition: 0.7s;
    }
  }

  &:hover {
    transform: rotate(-15deg);
    transition: 0.7s;
    svg {
      animation-play-state: paused;
    }
    .phone-button__phone {
      path {
        fill: rgb(178, 234, 93);
        transition: 0.7s;
      }
    }
  }

  .phone-button__circle {
    animation-delay: 0.2s;
    width: 70%;
    height: 70%;
  }

  .phone-button__inner-circle {
    width: 100%;
    height: 100%;
  }

  .phone-button__phone {
    animation-delay: 0.2s;
    width: 50%;
    height: 50%;
  }

  @keyframes pulse {
    0% {
      transform: scale(1) translate(-50%, -50%);
    }
    50% {
      transform: scale(1.1) translate(-50%, -50%);
    }
    100% {
      transform: scale(1) translate(-50%, -50%);
    }
  }
}
