.container .content-info-cart {
	.select2 {
		.select2 {
			&-selection {
				background: $color-default-secondary;
				border: none;
				box-shadow: none;

				&__arrow {
					border-left: none;
					width: 30px;

					b {

						content: "";
						position: absolute;
						right: 2px;
						top: 50%;
						transform:translate(0,-50%) rotate(-45deg);

						padding: 4px;
						border: solid $color-default-quinary;
						transition:0.3s;
						border-width: 0px 0px 1px 1px;
					}
				}
			}

		
		}

		

	}
}


.container .content-info-cart .select2-container--krajee.select2-container--open {
	.select2-selection--single .select2-selection__arrow b {
		border-color: $color-default-quinary;
		border-width: 0px 0px 1px 1px;
		transform:translate(0,25%) rotate(135deg);
		top: 40%;

	}
}

.select2-container--open.select2-container--krajee {

	.select2-search--dropdown .select2-search__field {
		border: none;
		box-shadow: none;
		background-position: right 10px top 5px;
	}

	.select2-dropdown {
		border: none;
	}

	.select2-results__option[aria-selected=true] {
		background: $color-default-secondary;
		color: $color-default-quaternary; 
	}

}

.select2-results__options {

	&::-webkit-scrollbar { 
	    width: 15px;
	    background: $color-default-primary;
	} 

	&::-webkit-scrollbar-thumb {
		background: $color-default-secondary;
	}

	&::-webkit-scrollbar-button:vertical:decrement,
	&::-webkit-scrollbar-button:vertical:increment { 
		height: 0;
	}
	
}
