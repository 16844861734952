.slider-header {
  width: 100%;
  height: 100%;
  position: relative;
  .slider__dots {
    padding-top: 40px;
  }
  &.slick-initialized {
    opacity: 1;
    visibility: visible;

    .slider-header__slide {
      opacity: 1;
      visibility: inherit;
      height: auto;
      padding-bottom: 20px;
    }
  }
}

.slider-header__slide {
  width: 100%;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 1;

  &:not(:first-child) {
    opacity: 0;
    visibility: hidden;
    height: 0px;
    padding-bottom: 0px;
  }
}

.slider-header__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 730px) {
  .slider-header {
    padding-top: 10px;

    &:after {
      display: none;
    }

    .slider__dots {
      padding-top: 0;
      position: relative;
      top: -20px;

      li {
        margin: 0 5px;
      }
    }

    &__img {
      height: 210px;
    }

    .header__title {
      font-size: 22px;
      line-height: 30px;
      font-family: $medium;
      text-transform: uppercase;
      padding-top: 10px;
    }

    .header__text {
      font-size: 16px;
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 12px;
    }

    .header__btn {
      width: 64px;
      height: 30px;
      text-indent: -9999px;
      background-image: url('./../../images/views/blocks/c-button/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
    }

    &.slick-initialized &__slide {
      padding-bottom: 0;
    }
  }
}
