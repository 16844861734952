.basket-order{
	background: $color-default-secondary;
	position: fixed;
	top: 0;
	width: 410px;
	height: 100%;
	z-index: 500;
	right: 0;
	transform:translateX(100%);
	transition:0.3s;
	

	display: flex;
    flex-direction: column;


	&__title{
		font-size: 18px;
	}
    
} 

.basket-order__close{
	top: 0;
	left: 0;
	position: relative;
	transform:translate(0,0);
	width: 20px;
	height: 20px;
}

.basket-order__head{
	background: $color-default-primary;
	position: relative;
	padding: 35px 20px;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	
}
.basket-order__content{
	height: calc(100% - 105px - 171.5px);
	-ms-overflow-y: auto;
	    overflow-y: auto;
    padding: 0 20px 35px;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
    &--height-primary{
    	height: calc(100% - 93px);
		background: $color-default-primary;
    }
}

.basket-order__item{
	position: relative;
	display: flex;
	padding: 15px 0;
}


.basket-order__image{
    width: 60px;
    height: 60px;
    position: relative;
    margin-right: 11px;
}

.basket-order__img{
    display: block;
}

.basket-order__desc{
	width: calc(100% - 71px);
	color: rgba(0, 0, 0, 0.8);
}

.basket-order__price{
	font-family: $light;
	font-size: 14px;
	padding-top: 10px;
	color: #000;
	& span{
		color: rgba(0, 0, 0, 0.25);
		padding-right: 5px;
	}
}

.basket-order__total{
	display: flex;
    justify-content: space-between;
	padding-top: 15px;
	padding-bottom: 40px;
	border-top: 1px solid rgba($color-default-quaternary, 0.2);
}

.basket-order__total-text{
	font-weight: normal;
}

.basket-order__total-price{
}

.basket-order__row{
	font-family: $light;
	display: flex;
	margin: 0px -5px 0;
}
.basket-order__col-primary{
	width: 50%;
	padding:0 5px;
}
.basket-order__footer{
	background: $color-default-primary;
	padding: 25px 20px;
}

.basket-order__footer-text{
	font-family: $light;
	font-size: 14px;
	color: $color-default-quinary;
	padding-bottom: 20px;
	span{
		font-family: $regular;
	}
	
}

.basket-order__footer-box{
	display: flex;
}

body.js-open-basket{
	.basket-order{
		transform:translateX(0);
	}
}



@media (max-width:991px) {

}

@media (max-width:730px) {
	.basket-order__item{
		padding: 10px 0;
	}

	.basket-order__item_pb{
		padding-bottom: 25px;
	}
	.basket-order__head{
		padding: 20px;
	}
}

@media (max-width:450px) {
	.basket-order{
		width: 100%;
	}
}

@media (max-width:380px) {
	.basket-order__desc{
		font-size: 12px;
	}

	.basket-order__image{
		width: 85px;
		height: 85px;
	}

	.basket-order__name{
		font-size: 14px;
	}
	.basket-order__content{
	    padding: 0 15px 15px;
	}
	.basket-order__footer{
		padding: 10px 20px;
	}
	.basket-order__footer-text{
		padding-bottom: 10px;
	}
	.basket-order__footer-box{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	    padding-bottom: 10px;
		.input{
			margin: 0 0px 5px 0;
		}
		.btn{
			height: 45px;
			line-height: 45px;
			max-width: 100%;
		}
	}
}