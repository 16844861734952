.error{
	height: 100vh;
    width: 100%;
}

.error__wrapp{
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
	height: 100%;
	max-width: 499px;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

.error__number{
	font-size: 200px;
}

.error__img,
.error__number-span{
	display: inline-block;
	vertical-align: bottom;
}

.error__text{
	font-size: 16px;
	color: $color-default-quinary;
	padding: 30px 0 85px 0;
}

.error__btn{
	
}




@media (max-width:991px) {

}

@media (max-width:730px) {
	
}

@media (max-width:530px) {
	.error__number{
		font-size: 180px;
		line-height: 180px;
	}

	.error__number{
		img{
			max-width: 135px;
		}
	}

}

@media (max-width:380px) {
	.error__number{
		font-size: 158px;
		line-height: 158px;
	}
}