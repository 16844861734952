.c-select{
	font-size: 14px;
	 border-radius: 6px;
	

	// STYLES
	.ss-single-selected,
	.ss-content{
		border: 1px solid transparent;
	}
	.ss-single-selected{
		height: 34px;
        // border-radius: 4px 4px 0 0;
	}
	.ss-content{
        border-radius: 0px 0px 4px 4px;
	}


	&--width-primary{
		max-width: 180px;
		
	}
	&--width-full{
		max-width: 100%;
	}
	&--bg-primary{
		.ss-single-selected,
		.ss-content{
			background: lighten($color-default-tertiary,7%);
		}
		
	}
	&--primary{
		border: 1px solid $color-default-tertiary;
		// .ss-single-selected,
		// .ss-content{
		// }
	}
	&--height{
       
		.ss-single-selected{
			height: 45px;
		}
	}
	
 	
}