

.c-link{
	
	&--primary{
		text-decoration: underline;
		color: rgba($color-default-quinary,0.6);
		
	}
	&--icon{
		display: flex;
        align-items: center;
	}

	&:hover{
		text-decoration: none;
	}
}