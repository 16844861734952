.gallery{
	background: $color-default-primary;
	padding: 45px 0 160px 0;
}

.gallery__wrapp{
	padding-top: 45px;
}

.gallery__row{
	margin: 0 -5px;
	font-size: 0;
}

.gallery__col{
	display: inline-block;
	vertical-align: middle;
	padding: 5px;
}

.gallery__image{
	height: 280px;
	width: 100%;
    position: relative;
    img{
    	height: 100%;
    }
}

.gallery__image{
	
}

@media (min-width:1199px) {
	.gallery__col{		
		height: 280px;
	}
	.gallery__col:nth-child(9n+1),
	.gallery__col:nth-child(9n+2),
	.gallery__col:nth-child(9n+3),
	.gallery__col:nth-child(9n+4),
	.gallery__col:nth-child(9n+5) {
		width: 20%;
	}
	.gallery__col:nth-child(9n+6),
	.gallery__col:nth-child(9n+7),
	.gallery__col:nth-child(9n+8),
	.gallery__col:nth-child(9n+9) {
		width: 25%;
	}
	.gallery__image{
		height: 100%;
		position: relative;
		padding-top:calc(280% * 100 / 420);
	}

	.gallery__img{
	    object-fit: cover;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	}
}


@media (max-width:1199px) {
	.gallery__col{
		width: 33.3333333%;
	}
	.gallery__image{
		height: 100%;
		position: relative;
		padding-top:calc(280% * 100 / 420);
	}

	.gallery__img{
	    object-fit: cover;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	}
}


@media (max-width:730px) {
	.gallery__col{
		width: 50%;
	}
}

@media (max-width:530px) {
	.gallery__col{
		width: 100%;
	}
}