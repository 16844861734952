.how-we-work {
  background: $color-default-primary;
  padding: 60px 0 123px 0;
}

.how-we-work__row {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 52px 0;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.how-we-work__col {
  width: 16.666666666667%;
  padding: 0 10px;
}

.how-we-work__box {
  text-align: center;
}

.how-we-work__image {
  position: relative;
  margin: 0 auto;
  // svg{
  // path{
  // 	fill: $color-primary;
  // }
  // }
}
.how-we-work__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how-we-work__arrow {
  &::before {
    content: '';
    width: 32px;
    height: 8px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -25px;
    background: url(./../../images/icons/icon-arrow.svg) no-repeat
      center/contain;
  }
  &_none {
    &::before {
      display: none;
    }
  }
  &_reverse {
    &::before {
      transform: translate(0, -50%) scaleY(-1);
    }
  }
}

.how-we-work__text {
  font-family: $regular;
  font-size: 14px;
  padding-top: 30px;
}

@media (max-width: 991px) {
  .how-we-work {
    padding: 50px 0 70px 0;
  }
  .how-we-work__row {
    padding: 35px 0 0 0;
  }
  .how-we-work__arrow {
    &::before {
      right: -40px;
    }
  }
}

@media (max-width: 730px) {
  .how-we-work {
    padding: 50px 0;
  }
  .how-we-work__col {
    width: 33.33333333333333%;
  }

  .how-we-work__arrow {
    &::before {
      right: -55px;
    }
  }
}

@media (max-width: 530px) {
  .how-we-work__col {
    width: 50%;
  }

  .how-we-work__arrow {
    &::before {
      right: -20px;
    }
  }

  .how-we-work__arrow-mobile {
    &::before {
      display: none;
    }
  }
}

@media (max-width: 380px) {
}
