

.order-info__wrapp{
	max-width: 800px;
	width: 100%;
	margin: 0 auto; 
}



.order-info__list{
	li{
		padding-top: 20px;
		position: relative;
		display: flex;
        justify-content: space-between;
        &:after {
			content: '';
			position: absolute;
			bottom: 4px;
			left: 0;
			width: 100%;
			height: 1px;
			border-bottom: 1px dashed rgba($color-default-sextuple,.1);
		}
	}
	p{
		background: $color-default-primary;
		font-size: 18px;
	}
}