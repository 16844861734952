

.form-group{
	&.has-error{
		.help-block{
			margin-top: 10px;
		}
	}
}

.help-block{
	font-size: 14px;
	color: $color-default-septenary;
}