.content{
	width: calc(100% - 315px);
	padding: 0 15px;
	
	&--primary{
		width: calc(100% - 275px);
	}
	&--secondary{
		width: calc(100% - 225px);
	}

	&--width-full{
		width: 100%;
	}
}

.content__wrapp{
	&--primary{
		background: $color-default-primary;
	    border-radius: 10px;
	    border: 1px solid rgba($color-default-quinary, 0.2);
	    width: 100%;
	    margin: 0 auto;
	}

	&--padding-primary{
		padding: 50px 45px 45px 45px;
	}

	

	&--catalog {
		background: $color-default-secondary;
	}
}
.content__row{
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	    -webkit-align-items: flex-start;
	            align-items: flex-start;
	    margin: 0 -10px;
	&--primary{
		
	}
	&--center{
		-webkit-justify-content: center;
		        justify-content: center;
	}
}

.content__col{
	padding: 15px 10px;
	width: 33.33333%;
	
	&--primary{
		width: 25%;
	}
}


.content__more-block{
	text-align: center;
	padding-top: 70px;
}
.content__more{
	font-family: $light;
	color: #575757;
	position: relative;
	border: none;
	cursor: pointer;
	text-transform: uppercase;
	display: inline-block;
	font-size: 0;
	span{
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
	}
	svg{
		display: inline-block;
		vertical-align: middle;
		transition:1s;
		margin-right: 8px;
		path{
			fill: $color-default-quaternary;
		}
	}
	&:hover{
		svg{
			transform:rotate(-135deg);
		}
	}
}



// For Article

.content__row-article{
	margin: 0 -25px;
}




@media (max-width:1199px){
	.content__row--primary{
		margin:0 -5px;
		.content__col{
			padding: 15px 5px;
		}
	}
	.content__wrapp{
		&--padding-primary{
			padding: 15px 20px;
		}
	}
	.content__col{
		width: 50%;
		&--primary{
			width: 33.33%;
		}
	}
}

@media (max-width:991px){
	.content {
	    width: calc(100% - 230px);
    	padding: 0 5px;
    	&--secondary,
    	&--primary{
    		width: 100%;
    	}
    }
    .content__wrapp{
		&--padding-primary{
			padding: 20px 20px;
		}
	}
	.content__row{
	    margin: 0 -5px;
	}
	.content__col{
		padding: 15px 5px;
	}

}

@media (max-width:730px) {
	.content{
		width: 100%;
	}
	.content__col{
		padding: 20px 5px;
	}
}

@media (max-width:400px) {
	.content__col{
		width: 100%;
		padding: 25px 5px;
	}
}

@media (max-width:480px) {
	._catalog .content__col{
		width: 50%;
	}
}