.header-primary{
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover; 
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	
}


.header-primary__wrapp{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	min-height: calc(100vh - 125px);
	padding: 30px 0;
}

.header-primary__text{
	font-size: 24px;
    padding: 20px 0 35px 0;
}