.category-date{
	display: flex;
	// a,p{
	// 	color: $color-primary;
	// }
	&--primary{
		font-size: 12px;
	}
}


.category-date__link{
	font-family: $medium;
	position: relative;
	padding: 0 30px 0 0;
	&:after{
		content: '';
		width: 2px;
		height: 2px;
		// background: $color-primary;
		position: absolute;
		right: 15px;
		top: 50%;
		transform:translate(0, -50%);
	}
}

@media (max-width:1199px) {
	.category-date__link{
		padding: 0 10px 0 0;
		&:after{
			right: 5px;
		}
	}
}
