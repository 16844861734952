%arrow-center {
  top: 50%;
  transform: translate(0, -50%);
}

.arrow {
  cursor: pointer;
  z-index: 4;
  position: absolute;
  &::before {
    content: '';
    position: absolute;
    @include width_height(32%);
    display: block;
    border: solid #afadab;
    border-width: 1px 0 0 1px;
    top: 50%;
    left: calc(50% + 8%);
  }
  &.slick-disabled {
    opacity: 0;
    pointer-events: none;
  }
}
.arrow_left {
  &::before {
    left: calc(50% + 8%);
  }
}
.arrow_right {
  &::before {
    left: calc(50% - 8%);
  }
}

.arrow_center {
  &.arrow_left {
    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &.arrow_right {
    &::before {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
}

.arrow_header {
  @extend .arrow_goods;
}

.arrow_recommend {
  background: $color-default-primary;
  border: 1px solid $color-default-secondary;
  @extend %arrow-center;
  @include width_height(38px);
  border-radius: 50%;

  &.arrow_left {
    left: -18px;
  }
  &.arrow_right {
    right: -18px;
  }
}

.arrow_goods {
  @extend %arrow-center;
  @include width_height(38px);
  border-radius: 50%;
  background: $color-default-secondary;

  &.arrow_left {
    left: -48px;
  }
  &.arrow_right {
    right: -48px;
  }
}

.arrow_reviews {
  @extend %arrow-center;
  @include width_height(38px);
  border-radius: 50%;
  background: $color-default-secondary;

  &.arrow_left {
    left: -160px;
  }
  &.arrow_right {
    right: -60px;
  }
}

.arrow_complete {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  @include width_height(38px);
  right: calc(100% + 10px);
  transform: rotate(90deg);
  &:hover {
    background: $color-default-primary;
  }
  &.arrow_left {
    top: -5px;
  }
  &.arrow_right {
    bottom: -5px;
  }
}

@media (max-width: 1199px) {
  .arrow_recommend {
    &.arrow_right {
      right: -20px;
    }
  }
  .arrow_goods {
    &.arrow_left {
      left: -38px;
    }
    &.arrow_right {
      right: -38px;
    }
  }
}

@media (max-width: 991px) {
  .arrow_reviews {
    &.arrow_left {
      left: -60px;
    }
    &.arrow_right {
      right: -60px;
    }
  }
}

@media (max-width: 730px) {
  .arrow_goods {
    &.arrow_left {
      left: -45px;
    }
    &.arrow_right {
      right: -45px;
    }
  }
}

@media (max-width: 580px) {
  .arrow_goods {
    &.arrow_left {
      left: 0px;
    }
    &.arrow_right {
      right: 0px;
    }
  }
  .arrow_reviews {
    &.arrow {
      top: 36px;
    }
    &.arrow_left {
      left: 30px;
    }
    &.arrow_right {
      right: 30px;
    }
  }
}

@media (max-width: 480px) {
  .arrow_header {
    display: none;
  }
}

.slider__dots {
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;

  li {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    background: #c4c4c4;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    button {
      cursor: pointer;
      opacity: 0;
      width: 12px;
      height: 12px;
    }
  }
}
