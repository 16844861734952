.pagination {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  li {
    margin: 0 2.5px;
    a,
    span {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      position: relative;
      line-height: 30px;
      color: $color-default-quaternary;
      display: block;
      transition: all 0.3s;
      font-family: $medium;
      &:hover {
        background: rgba($color-default-quinary, 0.1);
      }
    }

    &.prev,
    &.next {
      a,
      span {
        font-size: 0px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          padding: 3px;
          border: solid $color-rare-primary;
          transition: all 0.3s;
          border-width: 0 0 1px 1px;
        }
      }
      &.disabled {
        opacity: 0;
      }
    }
    &.prev {
      a,
      span {
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
          left: calc(50% + 2px);
        }
      }
    }
    &.next {
      a,
      span {
        &::before {
          transform: translate(-50%, -50%) rotate(-135deg);
          left: calc(50% - 2px);
        }
      }
    }

    &.active {
      a {
        background: $color-rare-primary;
        color: $color-default-primary;
      }
    }
  }
}
