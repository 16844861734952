.menu-info{
	
}

.menu-info__items{
	&:first-child{
		padding-bottom: 5px;
	}
}

.menu-info__list{
	position: relative;
	z-index: 10;
	display: flex;
    align-items: center;
	a{
		font-size: 18px;
		padding: 0 5px;
	}
	li {
		//margin-left: 10px;
	}
	&--primary{
		@media (max-width:991px) {
			margin: 0 -7.5px;
			//display: none;
			li{
				padding:0 7.5px;
			}
		}
	}
	
}

.menu-info__number{
	color: $color-default-quinary;
	padding: 0 10px 0 0 !important;
	margin-bottom: 5px;
	
	&--primary{
		@media (max-width:991px) {
			width: 50px;
			height: 50px;
			background: rgba($color-default-sextuple, 0.05);
			display: flex;
		    align-items: center;
		    justify-content: center;
		    padding: 0 !important;
		    margin-bottom: 0;
		}
	}
	svg{
		path{
			fill:$color-default-primary;
		}
	}
}

.menu-info__address{
	font-weight: normal;
	font-size: 14px;
	color: $color-default-quinary;
	position: relative; 
	padding-left: 20px;
	svg{
		position: absolute;
		left: 0;
		top: 50%;
		display: block;
		transform:translate(0,-50%);
		width: 14px;
		height: 14px;
		// path{
		// 	fill: $color-primary;
		// }
	} 
}

.menu-info__social{
    border-radius: 100%;
	display: flex;
    align-items: center;
    justify-content: center;
	width: 30px;
	height: 30px;
	background: rgba($color-default-sextuple, 0.05);
	svg{
		width: 14px;
		height: 14px;
		path{
			fill:$color-default-primary;			
		}
	}

	&--bg{
		&-vk{
			background: rgba(#4A76A8,0.5);
		}
		&-youtube{
			background: rgba(#FF0000,0.5);
		}
		&-instagram{
			background: rgba(#E55232,0.5);
		}
		&-facebook{
			background: rgba(#4A76A8,0.5);
		}
		&-viber{
			background: rgba(#8834F3,0.5);
		}
		&-whatsapp{
			background: rgba(#62C724,0.5);
		}
		&-telegram{
			background: rgba(#45D2FF,0.5);
		}

	}
	
}


@media (max-width:991px) {
	.menu-info{
		margin: 0 auto;
	}
}