.florists{
	background: #FFF;
	padding: 50px 0 0 0;
}

.florists__desc-block{
	padding: 75px 0 100px 0;
}

.florists__desc-text{
	font-size: 14px;
	color: rgba($color-default-quinary, 0.8);
	max-width: 700px;
	margin: 0 auto;
}