.swipe-addition-mobile{
	display: none;
	// width:100vw;
 //  	max-width: 100vw;
 //  	margin:0 calc(-50vw + 50%);
	width: 100%;
}

.swipe-addition-mobile__list{
    white-space: nowrap; 
	overflow: hidden;
	padding: 10px 15px;
	background: lighten($color-default-tertiary,7%);
	overflow-x: auto;
	display: flex;
	flex-wrap: wrap;
	li{
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px 10px 0;
	}
	a{
		display: block;
		cursor: pointer;
		border-radius: 6px;
		padding: 8px 20px;
		border: 1px solid darken($color-default-secondary,14%);
		font-family: $light;
		font-size: 14px;
		color: $color-default-quinary;
		&.active{
			// border: 1px solid $color-primary;
			// color: $color-primary;
		}
	}

}

.swipe-addition-desktop{
	display: block;
	// width:100vw;
	//  	max-width: 100vw;
	//  	margin:0 calc(-50vw + 50%);
	width: 100%;
	margin-top: -15px;
	.swipe-addition-mobile__list{
		background: inherit;
	}
}

@media (max-width:991px) {

}

@media (max-width:730px) {
	.swipe-addition-mobile{
		margin-top: 15px;
		display: block;
	}
	.swipe-addition-desktop{
		display: none;
	}
}

@media (max-width:530px) {


}

@media (max-width:380px) {
	
}