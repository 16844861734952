.c-basket {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 80px;
  max-width: 195px;
  width: 100%;
  position: relative;
  background: darken($color-default-secondary, 5%);
  color: $color-default-quinary;
  transition: all 0.3s;
  svg {
    width: 18px;
    fill: $color-default-quinary;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    max-width: 115px;
    padding-left: 15px;
    width: 100%;
  }
  &:hover {
    background: darken($color-default-secondary, 10%);
  }

  &__price {
    color: $color-default-quinary;
  }

  @media (min-width: 1199px) {
    &--promo {
      background: url('./../../images/views/blocks/c-basket/basket-promo-bg.png')
        no-repeat;
      background-color: $color-default-quinary;
      background-size: 100% 100%;
      &:after {
        content: 'ПРОМО';
        position: absolute;
        bottom: 3px;
        left: 5px;
        color: $color-default-octonary;
      }
      &::before {
        content: '';
        display: block;
        width: 36px;
        height: 30px;
        background: url('./../../images/views/blocks/c-basket/basket-promo-icon.png')
          100% 100% no-repeat;
        position: absolute;
        top: 0;
        left: 5px;
        z-index: 100;
      }
    }
  }

  @media (max-width: 1199px) {
    margin-right: 15px;
    &--promo {
      width: 78px;
      height: 42px;
      border-radius: 3px;
      background: $color-default-quinary
        url('./../../images/views/blocks/c-basket/basket-promo-icon.png') 5px
        120% no-repeat;
      justify-content: flex-end;
      padding-right: 10px;

      svg path {
        fill: $color-default-primary;
      }
      p {
        color: $color-default-primary;
      }
    }
  }
}
