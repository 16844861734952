


@media (min-width:730px) {
	.recommendation-slider{
		&--primary{
			.slick-track{
				padding: 70px 0;
			}
			.c-offer__link{
				font-size: 5px;
			}
			.product__category{
				font-size: 10px;
			}
			.product__name{
				font-size: 14px;
			}
			.product__like{
				svg{
					width: 15px;
				}
			}
			.product__price{
				&-sale{
					font-size: 10px;
					margin-right: 5px;
				}
				&-text{
					font-size: 12px;
				}
			}
			.product__price{
				padding-bottom: 0px;
			}
			.slick-slide{
				padding: 0px 20px;
				opacity: 0.5;
			}
			.slick-current {
				position: relative;
				z-index: 4;
				opacity: 1;
				.product{
					transform:scale(1.45);
				}
			}
		}
		&--secondary{
			max-width: 300px;
			margin: 0 auto;
		}
	}
	
}
@media (min-width:1199px) {
	.recommendation-slider{
		.c-offer{
			li{
				padding: 0.5px 0;
			}
		}
		.c-offer__link{
			font-size: 6px;
		}
		.product__price{
			&-sale{
				font-size: 12px;
				margin-right: 10px;
			}
			&-text{
				font-size: 14px;
			}
		}
	}
	
}


@media (max-width:530px) {
	.recommendation-slider{
		
	}

}


