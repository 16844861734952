.tags{
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin: 0 -5px;
	li{
		font-family: $light;
		font-size: 12px;
		display: flex;
		position: relative;
		padding: 5px 20px 5px 5px;
		background: $color-default-secondary;
		border: 0.5px solid $color-default-secondary;
		border-radius: 4px;
		margin: 5px;
	}
}