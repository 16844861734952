.color-icon{
	width: 40px;
	height: 8px;
	position: relative;
	z-index: 5;
	display: inline-block;
	cursor: pointer;
	transition:all 0.3s;
	&:hover{
		height: 12px;
	}
}

.color-text{
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
		width: calc(100% - 115px);
	a{
		font-family: $light;
		font-size: 16px;
		text-decoration-line: underline;
		color: $color-default-quinary;
		cursor: pointer;
		margin-right: 5px;
		&:hover{
			text-decoration-line: none;
		}
	}
}

@media (max-width:991px) {
	.color-text{
		width: calc(100% - 123px);
	}
}

@media (max-width:380px) {
	.color-text_fs{
		a{
			font-size: 12px;
		}
	}
}