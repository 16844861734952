.popup__block {
  max-width: 360px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 0 10px;
}

.popup__row {
  display: flex;
}

.popup__col {
  min-height: 475px;
  width: 50%;
  display: flex;
  align-items: center;
  &--bg-primary {
    background: url(./../../images/bg_popup.jpg) no-repeat left/cover;
  }
}

.popup__title {
  font-size: 40px;
  color: $color-default-quinary;
}

.popup__title_authorization {
  padding: 0px 0 45px 0;
  display: block;
}

.popup__link {
  font-size: 14px;
  color: $color-default-quinary;
  &:hover {
    text-decoration: underline;
  }
}

.popup__text {
  font-family: $light;
  font-size: 18px;
  color: $color-default-quinary;
  padding: 15px 0 25px 0;
}

.popup__input {
}

.popup__box-link {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.popup__btn {
  max-width: 100%;
  width: 100%;
}

.authorization-jsx-modal {
  max-width: 865px;
  background: #fff;
  min-height: 475px;
  position: relative;
  text-align: right;
  background: #fff;
}

.goods-jsx-modal {
  padding: 55px 0 60px 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  max-width: 600px;
  background: #fff;
  min-height: 500px;
  position: relative;
  text-align: center;
}

@media (max-width: 991px) {
  .popup_center {
    font-size: 0;
  }
}

@media (max-width: 630px) {
  .popup__col {
    width: 100%;
  }

  .popup__col--bg-primary {
    display: none;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 730px) {
}

@media (max-width: 480px) {
  .popup__text {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
}

.popup__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.popup__multi-city {
  text-align: center;

  .popup__block {
    padding-top: 65px;
    background: white;
    min-height: 340px;
    max-width: 580px;
    position: relative;

    span {
      font-size: 16px;
      font-family: $light;
    }
  }

  .popup__title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
  }

  .popup__col {
    width: 100%;
  }

  a {
    display: block;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 20px;
    transition: all ease 0.3s;
    font-weight: bold;
    color: black;
    opacity: 0.5;
    transition: all ease 0.3s;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.popup__order {
  //display: none !important;
  .popup__col {
    background: white;
    margin: 0 auto;
    width: 100%;
    position: relative;
    max-width: 660px;
    min-height: 470px;
  }

  .popup__block {
    padding: 45px;
    padding: 15px;
  }

  .popup__title {
    max-width: 400px;
    margin-bottom: 20px;
    span {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      display: block;
    }

    p {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .popup__block {
    max-width: 420px;
  }

  .btn {
    max-width: 100%;
  }
}
