.like-views{
	display: flex;
   
	width: 100%;
	margin: 0 -2.5px;
	&--width-primary{
		max-width: 200px;
	}
}

.like-views__col{
	width: 50%;
	padding:0 2.5px;
}


.like,
.views{
	display: flex;
    align-items: center;
}

.like{
	position: relative;
	left: 0px;
	bottom: 0px;
	z-index: 5;
	cursor: pointer;
	input[type=checkbox]{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
	path{
		transition:0.3s;
	}
	input[type=checkbox]:checked + svg path{
		fill:#B81E1F;
		stroke:$color-default-primary;
	}
	&:hover {
		path{
			fill:rgba(#B81E1F,0.5);
			stroke:$color-default-primary;
		}
	}

}
.like-views__count{
	padding-left: 5px;
}