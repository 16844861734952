.list-categories {
  li {
    position: relative;
  }
}

.list-categories__item {
}

.list-categories__link {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  // background: red;
  cursor: pointer;
  height: 30px;
  display: block;
  z-index: 1;
}

.list-categories__name {
  display: block;
  font-family: $light;
  color: $color-default-quinary;
  padding: 5px 0;
  position: relative;
  cursor: pointer;
  margin-bottom: 25px;
  &::before {
    content: '';
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);

    padding: 4px;
    border: solid $color-default-quinary;
    transition: 0.3s;
    border-width: 0px 0px 1px 1px;
  }
  &.active {
    font-family: $bold;
    &::before {
      transform: translate(0, -50%) rotate(135deg);
    }
  }
}

.list-categories__drop-list {
  padding: 0px 0 25px 5px;
  font-size: 14px;
  display: none;
}
.list-categories__drop-item {
  display: inline-block;
  color: $color-default-quinary;
  margin-bottom: 20px;
  margin-right: 10px;

  &--price {
    display: block;
  }
}
.list-categories__drop-name {
  // background: rgba($color-default-quinary,0.1);
  a {
    font-family: $light;
    color: $color-default-quinary;
    font-size: 14px;
    cursor: pointer;
    &:hover,
    &.active {
      font-family: $regular;
    }
  }
  padding-left: 20px;
  margin-bottom: 20px;
}

.list-categories__child-list {
  padding-left: 30px;
  display: none;
}

.list-categories__child-item {
  margin-bottom: 10px;
}

.list-categories__child-link {
  color: $color-default-quinary;
  &:hover {
    text-decoration: underline;
  }
}

.list-categories__range-tab {
}

.list-categories__range-item {
  display: inline-block;
  font-family: $light;
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  color: $color-default-sextuple;
  padding: 3px 12px 2px 12px;
  background: rgba($color-default-secondary, 1);
  border-radius: 6px;
  margin: 0 4px 5px 0;
  &.active {
    background: rgba(darken($color-default-secondary, 10%), 1);
    cursor: default;
  }
}

.list-categories__load-more {
  color: lighten($color-default-quinary, 5%);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 730px) {
  .list-categories__range-tab {
    &--mobile-primary {
      margin-top: 30px;
    }
  }
  .list-categories__item {
    &--mobile-hide {
      display: none;
    }
  }
}

@media (max-width: 530px) {
  .list-categories__drop-list_p2 {
    padding: 0 14px 25px 15px;
  }
}

@media (max-width: 380px) {
  .list-categories__block {
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
