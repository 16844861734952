%s-link-hover {
  transition: all 0.3s;
  // &:hover{
  // 	// color: $color-primary;
  // }
}

.head-top {
  position: relative;
  padding: 10px 0;
  background: $color-default-primary;
  @include media-breakpoint-up(xl) {
    border-bottom: 1px solid $color-default-tertiary;
  }
}

.stock {
  border: 1px dashed #c4322e;
  background: rgba(#c4322e, 0.1);
  border-radius: 4px;
  padding: 4px;
  &:hover {
    color: #404040 !important;
  }
}

.head-top__wrapp,
.head-top__list,
.head-top__icon,
.head-top__icon-link {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.head-top__wrapp {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.head-top__list {
  a {
    font-weight: normal;
    font-size: 14px;
    color: $color-default-quinary;
    margin-right: 20px;
    letter-spacing: 0.04em;
    // @extend %s-link-hover;
  }
}

.head-top__icon {
}

.head-top__icon-items {
  padding: 0 15px;
}

.head-top__icon-link,
.head-top__amount {
  font-size: 14px;
  color: $color-default-quinary;
  svg {
    width: 14px;
  }
  path {
    stroke: $color-default-quinary;
  }
}

.head-top__amount {
  padding: 0 0 0 5px;
}

body {
  &.js-body-head-catalog {
    .head-top {
      position: relative;
      z-index: 0;
    }
  }
  &.js-body-sidebar-menu {
    .head-top {
      pointer-events: all;
      &::before {
        opacity: 1;
      }
    }
    .logo {
      display: none;
    }
    .head-top__city-drop-down {
      display: block !important;
    }
  }
}

.head-top__wrapp-mobile {
  display: none;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .head-top {
    padding: 0px;
    &::before {
      content: '';
      display: block;
      border-bottom: 1px solid $color-default-tertiary;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: all 0.3s;
    }
  }
  .head-top__wrapp {
    display: none;
  }
  .head-top__wrapp-mobile {
    display: flex;
    &-icons {
      position: relative;
      .c-search {
        position: absolute;
        left: calc(100% + 10px);
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
  .head-bottom {
    display: none;
  }
}
