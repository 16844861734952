.select-option {
  position: relative;
  z-index: 15;
  &.active {
    .select-option__list {
      visibility: inherit;
      opacity: 1;
      pointer-events: auto;
      transform: translate(-50%, 10px);
      &--top-right {
        transform: translate(-50%, -10px);
      }
    }
  }
}

.select-option__choice {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: $color-default-quinary;
  padding-right: 20px;
  &--arrow {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 14px;
      height: 7px;
      background: url(./../../images/icons/icon-angel.svg) no-repeat
        center/contain;
    }
    img {
      max-width: 20px;
    }

    .select-option__text {
      font-size: 18px;
    }
  }
  &--fz {
    font-size: 14px;
  }
}
.select-option__icon {
  display: block;
  max-width: 18px;
}
.select-option__text {
  padding-left: 8px;
}

.select-option__list {
  white-space: nowrap;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  padding: 10px 0;
  min-width: 135px;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50px);
  background: $color-default-primary;
  border: 1px solid $color-default-tertiary;
  box-shadow: 0 2px 4px rgba($color-default-sextuple, 0.12);
  &::before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &::before {
    content: '';
    border-bottom: 8px solid $color-default-tertiary;
  }
  &:after {
    z-index: 2;
    content: '';
    border-bottom: 8px solid $color-default-primary;
    bottom: calc(100% - 1px);
  }
  a {
    padding: 8px 13px;
    &:hover {
      background: rgba($color-default-quinary, 0.1);
    }
  }
  &--top-right {
    top: auto;
    bottom: 100%;
    left: calc(50% + 40px);
    &::before,
    &:after {
      left: calc(50% - 40px);
      bottom: auto;
      top: calc(100% - 1px);
    }
    &::before {
      border-top: 8px solid $color-default-tertiary;
    }
    &:after {
      top: calc(100% - 1px);
      border-top: 8px solid $color-default-primary;
    }
  }
}

@media (max-width: 1199px) {
  .select-option__list {
    min-width: 108px;
    left: calc(50%);
  }
}
