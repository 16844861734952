%c-billet-promo-code--common {
  transition: all 0.3s;
}
%c-billet-promo-code--common-primary {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  height: 0px;
}
%c-billet-promo-code--common-primary--active {
  visibility: inherit;
  pointer-events: auto;
  opacity: 1;
}
.c-billet-promo-code {
  color: $color-default-primary;
  padding-top: 25px;
  //position: fixed;
  overflow: hidden;
  background: $color-rare;
  //transition: 0.3s;

  @extend %c-billet-promo-code--common;
  .c-close {
    top: 50%;
    margin-top: -7.5px;
    position: absolute;
    right: -30px;
  }
  &.active {
    background: $color-rare-primary;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    .c-billet-promo-code__wrapp {
      &-large {
        @extend %c-billet-promo-code--common-primary;
        padding: 0px;
      }
      &-small {
        @extend %c-billet-promo-code--common-primary--active;
        height: 100%;
      }
    }
  }
  &.mobile-active {
    .c-billet-promo-code__wrapp {
      &-large {
        &--content {
          &-large {
            @include media-breakpoint-down(lg) {
              // @extend %c-billet-promo-code--common-primary;
              display: flex;
            }
          }
          &-middle {
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding-top: 0;
    background: $color-rare-primary;

    .title {
      &--size {
        &-primary {
          font-size: 18px;
          @include media-breakpoint-down(xs) {
            // width: 100%;
            // br{
            // 	display: none !important;
            // }
          }
        }
      }
    }

    .c-close {
      right: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 5px 0;
  }
}

.c-billet-promo-code__wrapp-large {
  width: calc(100% - 50px);
  margin: 0 0 0 auto;
  height: 50px;
  background: $color-rare-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  padding-right: 15px;

  @extend %c-billet-promo-code--common;
  &:after {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    // background: radial-gradient(105.49% 197.77% at 4.99% 35.43%,rgba($color-default-primary,0.4) 0,rgba($color-default-primary,0) 30%);
    background: radial-gradient(
      105.49% 197.77% at 4.99% 35.43%,
      rgba(lighten($color-rare-primary, 30%), 0.4) 0,
      rgba(lighten($color-rare-primary, 30%), 0) 30%
    );
  }
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    right: 100%;
    display: block;
    width: 0;
    height: 0;
    border: 25px solid;
    border-color: transparent $color-rare-primary $color-rare-primary
      transparent;
    // box-shadow: 1px 2px 6px rgba($color-primary-sextuple, 0.6);
  }
  div,
  p,
  a,
  img {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    height: 100%;
    &::before,
    &:after {
      display: none;
    }
  }

  &--content {
    &-large {
      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
        // @extend %c-billet-promo-code--common-primary;
        display: none;
        padding: 10px 35px 10px 0;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 60px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-middle {
      @include media-breakpoint-up(lg) {
        display: none;
      }
      @include media-breakpoint-down(lg) {
        display: flex;
        padding: 5px 10px 5px 0;
        // @extend %c-billet-promo-code--common-primary-active;
      }
    }
  }
}
.c-billet-promo-code__wrapp-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  padding-right: 15px;
  margin: 0 0 0 auto;
  @extend %c-billet-promo-code--common-primary;
  // @extend %c-billet-promo-code--common;
}

.c-billet-promo-code__image-decoration {
  position: relative;
  left: -20px;
}

.c-billet-promo-code__image-decoration-primary {
  @include media-breakpoint-down(md) {
    max-width: 70px;
    position: relative;
    bottom: -20px;
  }
  @include media-breakpoint-down(sm) {
    // display: none;
    max-width: 40px;
  }
  &--primary {
    @include media-breakpoint-down(sm) {
      position: absolute !important;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
}
.c-billet-promo-code__c-text {
  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.c-billet-promo-code__btn-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-default-primary;
  font-size: 12px;
}

body {
  &.js-body-sidebar-menu {
    .c-billet-promo-code {
      pointer-events: all;
    }
  }
}
// .head.sticky .c-billet-promo-code {
// 	position: fixed;
// 	width: 100%;
// 	//height: 75px;
// 	top: 0;
// 	left: 0;

// 	&.active {
// 		//height: 25px;
// 	}
// }
