.title {
  font-size: 28px;
  display: block;
  &--center {
    text-align: center;
  }

  &--size {
    &-primary {
      font-size: 22px;
    }
    &-secondary {
      font-size: 20px;
    }
    &-tertiary {
      font-size: 40px;
    }
    &-quaternary {
      font-size: 28px;
    }
    &-quinary {
      font-size: 24px;
    }
  }

  &--line {
    position: relative;
    padding: 5px 0 10px 0;
    &::before {
      content: '';
      width: calc(100% + 30px);
      height: 1px;
      background: rgba($color-default-quinary, 0.2);
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--my-primary {
    margin: 30px 0;
  }
  &--mb-primary {
    margin-bottom: 15px;
  }

  &--font-medium {
    font-family: $medium;
  }
}

@media (max-width: 730px) {
  .title {
    &--size-secondary {
      font-size: 16px;
    }
  }

  .title-bar__sidebar-mobile-none {
    display: none;
  }
}
