.field{
	display: flex;
	position: relative;
	margin-bottom: 15px;
	&--primary{
		margin-left: -5px;
		margin-right: -5px;
	}
	&--mb-primary{
		margin-bottom: 25px;
	}

	&.disabled{
		opacity: 0.5;
		pointer-events: none;
	}

	&.nondisabled{
		opacity: 1;
		pointer-events: all;
	}

}

.field__col-6{
	padding: 0 5px;
	width: 50%;
}

.field__input-icon{
	position: relative;
	.input{
		text-indent: 40px;
	}
	svg{
		position: absolute;
	  	fill: $color-default-quinary;
	  	top: 22px;
	  	left: 20px;
	  	transform:translate(0,-50%);
	}
}


@media (max-width:380px){
	.field__input-icon{
		.input{
			text-indent: 30px;
		}
		svg{
			left: 13px;
			width: 16px;
		}
	}
}