%s-transform-50 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox {
  display: inline-block;
  cursor: pointer;
  &--mb-primary {
    margin-bottom: 20px;
  }
  &--width {
    &-full {
      width: 100%;
    }
  }

  &_effect {
    .checkbox__text {
      font-size: 14px;
    }
    .checkbox__checkbox:checked + .checkbox__nesting {
      .checkbox__text {
        color: $color-default-quinary;
      }
    }
  }

  &_mobile-m {
    margin: 15px 0 5px 0;
  }

  // &--style{
  // 	&-opacity{
  // 		.checkbox__checkbox:checked + .checkbox__nesting{
  // 			.checkbox__text{
  // 				opacity: 0.3;
  // 			}
  // 		}
  // 	}
  // }
}

.checkbox__checkbox {
  display: none;
  &:disabled + .checkbox__nesting {
    opacity: 0.4;
    cursor: default;
  }
  &:checked + .checkbox__nesting {
    .checkbox__square {
      &::before {
        opacity: 1;
      }
    }
    .checkbox__circle {
      &::before {
        opacity: 1;
      }
    }
  }
}

.checkbox__nesting {
  display: flex;
  align-items: center;
  &--position {
    &-top {
      align-items: flex-start;
    }
  }
}
.checkbox__square,
.checkbox__text {
  display: block;
}

.checkbox__square {
  @include width_height(14px);
  background: $color-default-primary;
  border: 1px solid $color-default-quinary;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    left: 1px;
    bottom: 1px;
    margin: auto;
    // background: $color-primary;
    opacity: 0;
    transition: 0.3s;
  }
}
.checkbox__circle {
  @include width_height(30px);
  margin: 0 15px 15px 0;
  position: relative;
  border-radius: 50%;
  &::before {
    content: '';
    position: absolute;
    @include width_height(14px);
    border-radius: 50%;
    @extend %s-transform-50;
    border-radius: 50%;
    // background: $color-primary;
    border: 1px solid $color-default-tertiary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: 0.3s;
  }
  &_white {
    border: 1px solid $color-default-secondary;
  }
}

.checkbox__text {
  font-family: $light;
  font-size: 12px;
  transition: 0.3s;
  width: calc(100% - 14px);
  padding-left: 10px;
  &--font-size {
    &--primary {
      font-size: 14px;
    }
  }
  &--style {
    &-primary {
      color: lighten($color-default-quaternary, 30%);
    }
  }
}

.checkbox__text,
.checkbox__link {
  cursor: pointer;
  color: #4d4d4d;
}

.checkbox__link {
  text-decoration: underline;
}

@media (max-width: 991px) {
}

@media (max-width: 730px) {
}

@media (max-width: 530px) {
}

@media (max-width: 380px) {
  .checkbox__link,
  .checkbox__text {
    text-align: left;
  }
}
