
// display
.d-flex{display: flex;}
.d-f-between{-webkit-justify-content: space-between; justify-content: space-between;}

.d-ib-vam{
	display: inline-block;
	vertical-align: middle;
}
.s-t-a-left{text-align: left;}
.s-cover-img{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit:cover;
}
.h-ta-center{
	text-align: center;
}
.h-d-flex{
	display: flex;
}
.h-flex-y-center{
	 align-items: center;
}




// LAZY LOAD

.h-lazy{
	transition:all 0.3s;
	&.loaded{
		
	}
}