.complete-bouquet {
  //min-height: 225px;
  padding-top: 30px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  &.ui-corner-all {
    opacity: 1;
    visibility: inherit;
  }
}

.complete-bouquet__head {
  padding-bottom: 10px;
}
.complete-bouquet__head,
.complete-bouquet__tabs-list,
.complete-bouquet__box {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.complete-bouquet__title {
  font-size: 14px;
  display: block;
  margin-right: 15px;
}

.complete-bouquet__tabs-item {
  a {
    font-family: $light;
    font-size: 14px;
    color: $color-default-quinary;
    padding: 6px 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

// CONTENT
.complete-bouquet__content {
  padding: 15px 0 15px 60px;
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.complete-bouquet__tabs-content-list {
}

.complete-bouquet__tabs-content-item {
}

.complete-bouquet__slider {
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  &.slick-initialized {
    opacity: 1;
    visibility: inherit;
  }
}

.complete-bouquet__slide {
}

.complete-bouquet__block {
}

.complete-bouquet__box {
}

.complete-bouquet__image {
  position: relative;
  width: 110px;
  height: 85px;
  img {
  }
}

.complete-bouquet__description {
  padding-left: 15px;
}

.complete-bouquet__sub-title {
  padding-top: 7px;
  white-space: nowrap;
}
.complete-bouquet__price {
  padding-bottom: 15px;
}

.complete-bouquet__sub-title,
.complete-bouquet__price {
  font-size: 16px;
  color: $color-default-quinary;
}

.complete-bouquet__price,
.complete-bouquet_checkbox__text {
  font-family: $light;
}

.complete-bouquet__checkbox {
  height: 15px;
  .checkbox__text {
    font-size: 14px;
  }
}

// CONTENT

.complete-bouquet__footer {
}

@media (max-width: 530px) {
  .complete-bouquet__slider {
    .arrow_complete {
      top: 50%;
      transform: translate(0, -50%) rotate(0deg);
      right: auto;
      bottom: auto;
      border-radius: 50px;
      background: $color-default-primary;
      -webkit-box-shadow: 0 2px 5px rgba($color-default-quinary, 0.3);
      box-shadow: 0 2px 5px rgba($color-default-quinary, 0.3);
      &.arrow_left {
        left: -10px;
      }
      &.arrow_right {
        right: -10px;
      }
    }
    // .arrow_complete

    /* position: relative; */

    // }
  }
  .complete-bouquet__tabs-list {
    overflow-y: auto;
  }
  .complete-bouquet__content {
    padding: 15px 0 15px 0px;
  }
  .complete-bouquet__title {
    white-space: nowrap;
    margin-right: 0px;
  }
}

@media (max-width: 991px) {
  // .complete-bouquet {
  // 	// display: none;
  // }
}
