.favorites{
	
}

.favorites__row{
	display: flex;
    flex-wrap: wrap;
}

.favorites__col{
	width: 25%;
}

@media (max-width:1199px) {
	.favorites__col{
		width: 33.33%;
	}
}
@media (max-width:730px) {
	.favorites__col{
		width: 50%;
	}
}
@media (max-width:430px) {
	.favorites__col{
		width: 100%;
	}
}