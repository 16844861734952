
.product-slider{
	opacity: 0;
	visibility: hidden;
	margin: 0 -15px;
	&.slick-initialized{
		opacity: 1;
		visibility: visible;
	}
	.slick-list{
		padding: 0 10px;
	}
	.slick-track{
		display: flex;
	}
}


.product-slider__slide{
	padding: 10px 0px;
 	min-height: auto;
 	height: auto;
}
