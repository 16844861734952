.order {
  border-top: 1px solid $color-default-tertiary;
  padding: 65px 0 150px 0;

  &--section-primary {
    text-align: center;
    padding: 70px 0 120px 0;
    background: no-repeat center/cover;
  }
}

.order__sub-title {
  text-align: center;
  padding: 15px 0;
  &--hide {
    display: none;
  }
  &--style-primary {
    max-width: 610px;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    // color: $color-primary;
    position: relative;
    padding: 60px 0;
    &::before {
      content: attr(data-title-sale);
      font-family: $light;
      text-transform: uppercase;
      font-size: 96px;
      color: rgba($color-default-quaternary, 0.15);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      letter-spacing: 0.1em;
    }
    span {
      position: relative;
      z-index: 3;
    }
  }
}

.order__wrapp {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  &--padding-primary {
    padding-top: 30px;
  }
  &--width-primary {
    max-width: 350px;
  }
}

.order__row {
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 -20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &.active-hidden {
    align-items: center;
    flex-direction: column;
    .order__col {
      &--secondary {
        .field:not(.field--primary) {
          display: none;
        }
      }
      &--full {
        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }
    }
  }
}

.order__col {
  width: 50%;
  padding: 0 20px;
  &--full {
    width: 100%;
  }
}

.order_services__text {
  max-width: 610px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .order__wrapp {
    &--padding-primary {
      padding-top: 40px;
    }
  }
  .order__row {
    margin: 0 -5px;
  }
  .order__col {
    padding: 0 5px;
  }
}

@media (max-width: 730px) {
  .order {
    padding: 50px 0 100px 0;
  }
  .order__row {
  }
  .order__col {
    width: 100%;
    padding: 0 20px;
  }

  .order__col-second {
  }

  .order__col-first {
  }
}

@media (max-width: 480px) {
  .order_services__title {
    font-size: 32px;
  }
  .order_services__sub-title {
    font-size: 18px;
    &::before {
      font-size: 74px;
    }
  }
}

@media (max-width: 380px) {
}
