.c-offer{
	position: absolute;
	left: 5px;
	top: 5px;
	z-index: 5;
	margin: -2.5px 0;
	li{
		padding: 2.5px 0;
	}
}

.c-offer__link{
	display: inline-block; 
	padding: 5px 15px;
	// background: $color-primary;
	font-size: 11px;
	color: $color-default-primary;
	border-radius: 50px;
	&--primary{
		background: $color-default-septenary;
		&:hover{
			background: darken($color-default-septenary,5%);
		}
	}
	&--secondary{
		// background: $color-primary;
		// &:hover{
		// 	background: darken($color-primary,5%);
		// }
	}
}
