

.number{
	display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-default-tertiary;
	border-radius: 4px;
    width: 100px;
	

	&--primary{
		border: none;
		.number__minus{
			opacity: 0;
			pointer-events: none;
		}
		.number__plus,
		.number__minus{
			width: 30px;
			padding: 4px 0;
	        border-radius: 50px;
	        border-width: 1px;
	        box-shadow: 1px 1px 4px rgba($color-default-sextuple,0.3);
	        &:active{
	        	transform:scale(1.1);
	        }
		}
	}
	&.active-primary{
		.number__minus{
			opacity: 1;
			pointer-events: auto;
		}
	}
}
.number__plus,
.number__minus{
	text-align: center;
	cursor: pointer;
	transition:all 0.2s;
	width: 30px;
	border:solid $color-default-tertiary;
	user-select: none;
	padding: 15px 0;
}
.number__plus{
	border-width: 0 0px 0 1px;
}
.number__minus{
	border-width: 0 1px 0 0px;
}

.number__input{
	width: 40px;
	text-align: center;
}

.number__input[type="number"]::-webkit-inner-spin-button,
.number__input[type="number"]::-webkit-outer-spin-button {
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  appearance: none;
	  margin: 0;
}


