.head-form{
	width: 100%;
	position: relative;
	svg{
		top: 50%;
		transform:translate(0,-50%);
		position: absolute;
		left: 10px;
		color: $color-default-quinary;
		z-index: 1;
	}
	&--width-primary{
		max-width: 200px;
	}
}

.head-form__input{
	width: 100%;
	height: 25px;
	background: lighten($color-default-tertiary,8%);
	border: 1px solid lighten($color-default-tertiary,3%);
    border-radius: 8px;
    text-indent: 35px;
    font-size: 14px;
    font-family: $light;
    &::placeholder{
		color: lighten($color-default-quinary,35%);
		font-size: 14px;
	}
}