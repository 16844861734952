

.c-modal{
	text-align: left;
	background: $color-default-primary;
	border: 1px solid $color-default-tertiary;
	box-shadow: 0px 4px 12px rgba($color-default-sextuple, 0.25);
	border-radius: 10px;
	padding: 40px;

	&--width{
		max-width: 780px;
	}

	&--document{
		
	}
}


.c-modal__container{
	max-width: 565px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	font-size: 18px;
}



.c-modal__property-img{
	position: absolute;
	right: 100%;
	bottom: 0px;
}


@media (max-width:991px) {
	.c-modal{
		padding: 30px 10px;
	}
}