.lk-data{
	
}

.lk-data__wrapp{
	width: 100%;
	padding: 0 50px 0 50px;
}

.lk-data__block{
	max-width: 510px;
	width: 100%;
	padding: 50px 0;
}


.lk-data__line{
	width: 100%;
	height: 1px;
	background: $color-default-tertiary;
}

.lk-data__sub-title{
	font-weight: 500;
	font-size: 18px;
	color: $color-default-quinary;
	padding: 0 0 20px 0;
}

.lk-data__box{
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	    -webkit-align-items: center;
	            align-items: center;
	    margin: 0 0 15px 0;
    &:last-child{
    	margin: 0;
    }
}

.lk-data__text{
	font-weight: 500;
	font-size: 14px;
	color: $color-default-quinary;
}

.lk-data__input{
	width: calc(100% - 150px);
	margin: 0;
}

.lk-data__btn{
	max-width: 290px;
}


@media (max-width:991px) {

}

@media (max-width:730px) {
	.lk-data__wrapp{
		padding: 0 ;
	}
	.lk-data__box{
		display: block;
	}

	.lk-data__text{
		padding-bottom: 5px;
	}

	.lk-data__input{
		width: 100%;
	}
}

@media (max-width:530px) {
	
}

@media (max-width:480px) {
	
}

@media (max-width:380px) {
	
}