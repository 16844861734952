.c-promo-code{
	.input{
		width: 220px;
	}
	.btn{
		width: 140px;
	}
	
	@include media-breakpoint-down(xs) {
		.input{
			max-width: 100%;
			width: 100%;
		}
		.btn{ 
			max-width: 100%;
			width: 100%;
		}
	}

}

.c-promo-code__input{
	
}

// c-promo-code

.c-promo-code__form{
	display: flex;
	@include media-breakpoint-down(xs) {
		display: block;
		
	}
}