.range{
    margin: 18px 0 15px 0;
	height: 2px;
	background: $color-default-tertiary;
	border: none !important;
	.ui-slider-handle{
		width: 20px;
		height: 20px;
		-webkit-border-radius: 100%;
		        border-radius: 100%;
		 // background: $color-primary;
		 // border-color: $color-primary;
		top: 50%;
    	transform: translateY(-50%);
		cursor: pointer;
	}
	.ui-slider-range{
		// background: $color-primary;
	}
}

@media (max-width:530px) {
	.range{
		max-width: 200px;
		width: 100%;
		margin: 18px auto 15px;
	}
}
@media (max-width:380px) {
	.range{
		.ui-slider-handle{
			width: 13px;
			height: 13px;
		}
	}
}