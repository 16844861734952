



.the-content{
	padding-top: 30px;
	max-width: 700px;
	width: 100%;
	margin: 0 auto;



	.title{
		margin-bottom: 15px;
	}

	ul{
		list-style-type: disc;
	}
	ol{
		list-style-type: decimal;
	}
	ul,
	ol{
	    padding-left: 20px;
	    margin-bottom: 5px;
	}
	@for $i from 1 through 6 {
	 	h#{$i}{
	 		margin-bottom: 15px;
	 	}
	 
	}
	table{
		td,
		tr{
			padding: 5px;
		}	
	}
	p{
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 768px){
	.the-content{
		tr{
			display: flex;
			flex-direction: column;
			align-items: center;
			p {
				text-align: center;
			}
		}


	}

}