.edge {
  padding-top: 35px;
}

.edge__col {
  padding: 0 0 38px 0;
  p {
    font-size: 14px;
    padding-top: 10px;
  }
  svg {
    width: 35px;
    height: 35px;
    // path{
    // 	fill: $color-primary;
    // }
  }
}

.edge__col_last {
  height: 200px;
  background: $color-default-primary;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #e1e1e1;
  &.active {
    width: 295px;
    padding: 0 25px 20px 25px;
    position: absolute;
    right: 0;
    .edge__text {
      text-decoration: none;
      padding: 0;
    }
  }
}
.edge__text {
  font-family: $light;
  font-size: 12px;
  text-decoration-line: underline;
  color: $color-default-quinary;
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    text-decoration: none;
  }
}

.edge__icon {
  width: 100%;
  height: 75px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 25px;
    left: 50%;
    width: 55px;
    height: 55px;
    background: url(./../../images/icons/guarantee.svg);
    transform: translate(-50%, 0%);
  }
}

.edge__box-text {
  font-family: $light;
  font-size: 12px;
  display: none;
}

@media (max-width: 991px) {
  .edge {
    display: flex;
    padding-top: 0;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .edge__col {
    padding-bottom: 30px;
    width: 33.333333333333%;
    &:last-child {
      width: 100%;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      text-align: left;
      padding-bottom: 0;
    }
  }
  .edge__box {
    max-width: 360px;
    width: 100%;
    padding-left: 10px;
    p {
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .edge__icon {
    width: 55px;
    height: 55px;
    &:after {
      top: -15px;
    }
  }
  .edge__box-text {
    display: block;
  }
  .edge__text {
    display: none;
  }
}

@media (max-width: 480px) {
  .edge__col_last {
    padding: 0 15px;
  }
}
