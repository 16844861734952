.sale{
	background: $color-default-secondary;
	padding: 45px 0 105px 0;
	text-align: center;

	&__item {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
	}

	&__sidebar {
		width: 281px;
		padding: 20px;
		background: $color-default-primary;
		border: 1px solid #E0E0E0;
		box-sizing: border-box;
		border-radius: 4px;
	}

	&__product {
		height: auto;
		margin-bottom: 30px;
	}
}

.sale__row{
	display: flex;
	margin: 0 -25px;
}


.sale__link{
	padding: 0 25px;
}



.sale__banner-text{
	font-size: 24px;
	padding-top: 15px;
}

@media (max-width:1216px) {
	.sale {
		&__sidebar {
			display: none;
		}
	}
}


@media (max-width:991px) {

}

@media (max-width:730px) {
	.sale__row{
		margin: 0 -10px;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}

}

@media (max-width:530px) {
	
}

@media (max-width:480px) {

}

@media (max-width:380px) {

}