.lk-history{
	text-align: center;
	width: 100%;
	margin: 0 auto;
	border-collapse: collapse;
	&--mt-primary{
		margin-top: 45px;
	}

	&--btn{
		display: flex;
	}
}

.lk-history__row{
	font-size: 16px;
	color: $color-default-quinary;
	text-align: center;
}

.lk-history__title{
	font-family: $medium;
	font-size: 18px;
	color: $color-default-quinary;
	position: relative;
	border-bottom: 2px solid $color-default-tertiary;
	padding: 0 0px 10px 0;
	&:nth-child(1){
		width: 15%;
	}
	&:nth-child(2){
		width: 20%;
		p{
			font-weight: 300;
			font-size: 12px;
			margin-top: 5px;
		}
	}
	&:nth-child(3){
		width: 25%;
	}
	&:nth-child(4){
		width: 20%;
	}
	&:nth-child(5){
		width: 20%;
	}
}

.lk-history__col{
	padding: 30px 0px;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		right: 0;
		left:auto;
		bottom: 0;
		width: 100%;
		height: 1px;
		background: $color-default-tertiary;
	}
	&:first-child{
		font-family: $medium;
		font-size: 18px;
		// color: $color-primary;
		text-align: left;
	}
}

.lk-history__help-icon{
	width: 14px;
	height: 14px;
	-webkit-border-radius: 50%;
	        border-radius: 50%;
	margin-right: 10px;
}


.lk-history__image{
    width: 65px;
    height: 45px;
    position: relative;
    display: inline-block;
}


@media (max-width:991px) {
	
}

@media (max-width:730px) {
	.lk-history__row{
		font-size: 14px;
	}

	.lk-history{
		.btn-history{
			margin-right: 0;
			margin-top: 5px;
			min-width: 100%;
		}
	}



	.lk-history__col{
		padding: 30px 0 65px 0;
		&:first-child{
			font-size: 16px;
			text-align: center;
		}
	}


	.lk-history__title{
		font-size: 16px;
		padding: 0 0px 10px 0;
		&:nth-child(1){
			width: 30%;
		}
		&:nth-child(2){
			width: 35%;
		}
		&:nth-child(3){
			width: 35%;
		}
	}
}

@media (max-width:530px) {


}

@media (max-width:380px) {
	.lk-history__row{
		font-size: 11px;
	}

	.lk-history__col{
		&:first-child{
		font-size: 14px;
	}
}
}