.c-total{
	text-align: right;
	
	&--mt-primary{
		margin-top: 15px;
	}
}

.c-total__text{
	font-size: 18px;
	text-transform: uppercase;
	span{
		font-family: $medium;
		padding-left: 10px;
	}
	i{
		font-style: normal;
		font-size: 16px;
		text-decoration: line-through;
	}
}
