.our-products {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 339px;
  background-color: #C4575D;
  border-radius: 4px;
  padding-left: 45px;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  overflow: hidden;
  transition: 0.5s;

  &::before {
    transition: 0.5s;
    @include default-pseudo-element(100%);
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:hover {
    transition: 0.5s;
    .our-products__img {
      transition: 0.5s;
      scale: 1.1;
    }

    &::before {
      transition: 0.5s;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.our-products__img {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s;
}

.our-products__title {
  color: #fff;
  font-family: Montserrat;
  font-size: clamp(15px, 3.5vw, 40px);
  font-style: normal;
  //font-weight: 550;
  line-height: 112%;
  z-index: 2;
  max-width: 650px;

  //@media (max-width: 880px) {
  //  width: 262px;
  //}
}

.our-products__text {
  color: #fff;
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px; /* 194.444% */
  padding: 10px;
  position: relative;
  width: fit-content;
  z-index: 2;

  &::before {
    @include default-pseudo-element(100%, 1px);
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
  }
}
