.co-worker {
}

.co-worker__wrapp {
  margin: 0 -15px;
}

.co-worker__tab {
  display: flex;
}

.co-worker__tab-item {
  width: 20%;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.3;
  padding: 0 15px;
  &.ui-state-active {
    opacity: 1;
  }
  a {
    display: block;
    padding-top: 100%;
    position: relative;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.co-worker__content {
  padding: 105px 0 270px 0;
}

.co-worker__content-item {
}

.co-worker__content-row {
}

.co-worker__list {
  display: flex;
}

.co-worker__item {
}

.co-worker__box {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
}

.co-worker__photo-img {
  position: relative;
  &:after {
    left: 25px;
    transform: scale(-1, 1);
  }
}

.co-worker__photo {
  width: 310px;
  height: 310px;
  border-radius: 100%;
  // overflow: hidden;
  margin-right: 85px;
  z-index: 5;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    right: -3px;
    bottom: -5px;
    width: 100%;
    height: 100%;
    // background: $color-primary;
  }
}
.co-worker__image {
  overflow: hidden;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.co-worker__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.co-worker__info {
  width: calc(100% - 395px);
}

.co-worker__name {
  font-weight: 600;
  font-size: 20px;
  display: block;
}

.co-worker__position {
  width: 100%;
  position: relative;
  font-size: 18px;
  padding: 15px 0 25px 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 67px;
    height: 41px;
    background: url(./../../images/icons/icon-quotes.svg) no-repeat
      center/contain;
  }
}

.co-worker__text {
  font-size: 16px;
  line-height: 22px;
  color: $color-default-quinary;
  max-width: 425px;
  margin-bottom: 15px;
}

.co-worker__list-photo {
  display: flex;
  width: 530px;
  //margin:-5px;
  li {
    padding: 10px;
  }
  a {
    display: block;
    width: 108px;
    height: 180px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 991px) {
  .co-worker__wrapp {
    margin: 0 -5px;
  }
  .co-worker__tab-item {
    padding: 0 5px;
  }

  .co-worker__photo {
    width: 250px;
    height: 250px;
  }
  .co-worker__info {
    width: calc(100% - 295px);
  }
}

@media (max-width: 730px) {
  .co-worker__tab {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .co-worker__tab-item {
    padding: 5px;
  }

  .co-worker__photo {
    margin-right: 0;
  }

  .co-worker__photo-img {
    margin: 0 auto;
    &::before {
      right: 10px;
    }
    &:after {
      left: 10px;
    }
  }

  .co-worker__info {
    width: 100%;
    padding-top: 20px;
  }
  .co-worker__content {
    padding-top: 30px;
  }
}

@media (max-width: 530px) {
  .co-worker__photo {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
}
