.c-catalog-menu {

  &__burger {
    margin-right: 10px;
    width: 24px;
    height: 20px;
  }

  &__shadow{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(black , 0.6);
    z-index: 100;
    display: none;

    @include media-breakpoint-down(lg) {
      display: none !important;
    }

  }

  &__wrap{
    column-count: 4;
  }


  &__dropdown {
    position: absolute;
    width: 100vw;
    background: white;
    padding: 30px 0;
    display: none;
    max-width: 1170px;
    border: 1px solid #E3E3E3;
    border-top: none ;

    max-height: 75vh;
    overflow-y: scroll;

    left: 50%;
    transform: translateX(-50%);
  }


  &__col{
    border-right: 1px solid #E3E3E3;
    padding: 10px 25px;
    &:last-child{
      border-right: none;
    }

    span{
      display: block;
      font-weight: bold;
      margin-bottom: 20px;
    }

    a{
      color: #525151;
      font-size: 14px;
    }

    ul{
      padding-left: 10px;
    }

    li{
      margin-bottom: 10px;
    }
  }
}

