.c-discount{
	background: $color-default-secondary;
	padding: 15px;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	border: 1px solid $color-default-tertiary;
	-webkit-border-radius: 8px;
	        border-radius: 8px;
	
	&--mt-primary{
		margin-top: 15px;
	}
}

.c-discount__text{
	font-size: 14px;
	span{
		font-family: $bold;
	}
}
