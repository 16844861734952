

.c-angel{
	position: relative;
	cursor: pointer;
	
	&--direction{
		&-down{
			border: solid;
			border-width:2px 2px 0 0;
			transform:rotate(135deg);
		}
	}
	
	&--width{
		padding: 5px;
		top: -2.5px;
	}

	&--color{
		border-color: $color-default-primary;
	}
}