.contacts{
	padding-top: 50px;
}

.contacts__row{
	width: 100%;
	text-align: center;
	font-size: 0;
	padding: 35px 0px 70px 0px;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.contacts__col{
	font-size: 14px;
	width: 33.33333333333333%;
	position: relative;
	padding: 0px 35px;
}

.contacts__box{
	position: relative;
	cursor: pointer;
	width: 100%;
	padding: 50px 10px 40px;
	&:hover{
		.contacts__box-border{
			 rect{
		 		stroke-dashoffset:0;
		 	}
	 	}
	} 
}

.contacts__box-border{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 100%;
	rect{
		// stroke:$color-secondary;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		transition:1s;
		stroke-linecap: round;
		stroke-dashoffset: 1062;
		stroke-dasharray: 1062;
	}
}

.contacts__sub-title{
	font-size: 24px;
}

.contacts__text{
	font-family: $light;
	padding: 25px 0 0px 0;
}


@media (max-width:991px) {
	.contacts__row{
		padding: 0px 0px 20px 0px;
	}

	.contacts__row{
		margin: 0;
	}
	.contacts__col{
		width: 100%;
		display: block;
		margin: 20px 0;
	}
}

@media (max-width:730px) {
	.contacts{
		padding-top: 40px;
	}

	

	.contacts__row{
		padding: 0px 0px 25px 0px;
	}

}

@media (max-width:530px) {
	.contacts{
		padding-top: 65px;
	}

	.contacts__row{
		padding: 25px 0px 20px 0px;
	}

	.contacts__col{
		margin: 0;
	}

	.contacts__box_mobile{
		padding-top: 0;
	}

	.contacts__box-border{
		display: none;
	}

}

@media (max-width:440px) {

}