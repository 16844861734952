.burger-primary {
  width: 50px;
  height: 50px;
  position: relative;
  &.active {
    .burger-primary__line {
      background: transparent;
      &::before {
        transform: translate(0, 0px) rotate(-45deg);
      }
      &:after {
        transform: translate(0, 0px) rotate(45deg);
      }
    }
  }
}

.burger-primary__line {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  &,
  &::before,
  &:after {
    width: 26px;
    height: 2px;
    background: $color-default-primary;
    border-radius: 3px;
    transition: all 0.3s;
  }
  &::before,
  &:after {
    content: '';
    position: absolute;
  }
  &::before {
    transform: translate(0, -6px);
  }
  &:after {
    transform: translate(0, 6px);
  }
}

.burger-primary__text {
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
