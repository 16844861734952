//b-breadcrumb

//ХЛЕБНЫЕ КРОШКИ
.c-breadcrumb {
  font-size: 12px;
  margin: 15px 0;

  //ОТСТУП ВНИЗ
  &--primary-mb {
    margin-bottom: 50px;
  }

  //СТИЛИ СПИСКА
  ul {
    display: flex;
    flex-wrap: wrap;

    //ПУНКТ СПИСКА
    li {
      margin-right: 20px;
      position: relative;
      a {
        color: #7e7b7b;
      }

      &.active {
        color: #7e7b7b;
        font-weight: bold;
      }

      //СТРЕЛКИ ПУНКТА СПИСКА
      &:after {
        content: '';
        width: 5px;
        height: 10px;
        background: url(./../../images/views/blocks/c-breadcrumb/arrow.svg);
        display: block;
        right: -13px;
        top: 3px;
        position: absolute;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
