.footer{
	background: $color-default-primary;
	padding-top: 40px;
	z-index: 16;
}

.footer__row-up{
	font-size: 0;
	margin: 0 -5px;
	padding: 50px 0 40px 0;
	position: relative;
}

.footer__col-up{
	width: 20%;
	display: inline-block;
}

.footer__col-up{
	vertical-align: top;
	padding: 0 5px;
}

.footer__col-up_middle{
	vertical-align: middle;
}

.footer__box-up{
}


.footer__logo{
	width: 155px;
}



.footer__items{
	margin-bottom: 12px;
	position: relative;
}

.footer__items_relative{
	margin: 0;
	position: absolute;
	top: 15px;
}


.footer__link{
	font-size: 14px;
	color:rgba( $color-default-quinary, 0.8);
	position: relative;
	transition:all 0.3s;
	&:hover{
		// color: $color-primary;
	}
}

.footer__caption{
	font-family: $medium;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 20px;
	color: $color-default-quinary;
}


.footer__row-down{
	padding: 35px 0;
	display: flex;
    align-items: center;
    flex-wrap: wrap;	
}


.footer__col-down{
	
	&--primary{
		width: 35%;
	}
	&--secondary{
		width: calc(100% - 35% - 35%);
		text-align: center;
	}
}



.footer__box-down{
	display: inline-block;
}

.footer__text{
	font-size: 14px;
	color:rgba( $color-default-quinary, 0.8);
	padding-bottom: 8px;
}

.footer__confidentiality{
	text-decoration-line: underline;
	&:hover{
		text-decoration-line: none;
		
	}
}

.footer__list-link-image{
	display: flex;
	margin: -2.5px;
	li{
		padding: 2.5px;
		max-width: 100%;
		width: auto;
	}

}



.footer__list{
}

.footer__item{
	padding:0 25px;
	display: inline-block;
}

.footer__social{
	display: block;
	svg{
		path{fill: $color-default-quinary; }
	}
	&:hover{
		svg{
			path{fill: darken($color-default-quinary, 50%);}
		}
	}
}


@media (max-width:991px) {
	.footer__row-up{
		padding: 15px 0 10px 0;
	}
	.footer__col-up{
		display: none;
	}
	.footer__col-up_mobile{
		display: block;
		padding: 0;
		width: 100%;
	}
	.footer__logo{
		margin: 0 auto;
		width: 130px;
		display: block;
		text-align: center;
	}

	.footer__row-down{
		display: flex;
        flex-direction: column-reverse;
		padding: 25px 0 40px 0;
	}

	.footer__col-down{
		&--primary,
		&--secondary{
			margin-bottom: 20px;
			width: 100%;
		}
		&--secondary{
			order:1;
		}
	}
	.footer__list-link-image{
        justify-content: center; 
	}

	.footer__text{
		font-size: 16px;
	}

}

@media (max-width:730px) {
	.footer__social{
		svg{
			width: 27px;
			height: 27px;
		}
	}
}

@media (max-width:530px) {


}