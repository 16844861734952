.view-item__slider-big-slide {
  position: relative;
}
.view-item__slider-big-image {
  padding-top: 100%;
  position: relative;
  display: block;
  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

.view-item__slider-small {
  overflow: hidden;
  &::before {
    content: '';
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 251, 249, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 251, 249, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 251, 249, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    pointer-events: none;
  }
  .slick-list {
    margin-left: -6px;
    width: calc(100% + 50px);
  }
}

.view-item__slider-small-slide {
  padding: 12px 6px;
  cursor: pointer;
  &.slick-current {
    cursor: default;
    .view-item__slider-small-image {
      &::before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
.view-item__slider-small-image {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    // border: 3px solid $color-primary;
    width: 108px;
    height: 108px;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    transform: scale(0.4);
    opacity: 0;
    z-index: 3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  position: relative;
  padding-top: 100%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.view-item__slider-small-image_video {
  position: relative;
  display: block;
  &:after {
    content: '';
    display: block;
    position: absolute;
    transform-origin: center;
    width: 57px;
    height: 57px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    background: url(./../../images/icons/icon-slider-video.svg) no-repeat
      center/contain;
    opacity: 0.9;
    cursor: pointer;
    z-index: 5;
    transition: 0.3s;
  }
  &:hover {
    &:after {
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 1;
    }
  }
}

// ARROWS
.view-item__arrow {
  position: relative;
  opacity: 0;
  transition: all 0.3s;

  position: absolute;
  top: 0;
  width: 90px;
  height: 100%;
  z-index: 4;
  cursor: pointer;
  &::before,
  &:after {
    content: '';
    transition: all 0.3s;
    position: absolute;
  }
  &::before {
    width: 30px;
    height: 30px;
    top: 50%;
    border: solid $color-default-primary;
    border-width: 2px 0 0 2px;
    transform: rotate(-45deg) translate(0, -50%);
    z-index: 55555;
  }
  &:after {
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba($color-default-sextuple, 0.1);
  }
  &:hover {
    opacity: 1;
    &:after {
      opacity: 1;
    }
  }
}

.view-item__arrow-left {
  &::before {
    left: 50%;
    border-width: 2px 0 0 2px;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
  &,
  &:after {
    left: 0;
  }
}
.view-item__arrow-right {
  &::before {
    right: 50%;
    border-width: 2px 2px 0 0px;
    transform: rotate(45deg) translate(50%, -50%);
  }
  &,
  &:after {
    right: 0;
  }
}

@media (max-width: 991px) {
  .view-item__slider-small-image_video {
    &:after {
      width: 41px;
      height: 41px;
    }
  }
}

@media (max-width: 730px) {
  .view-item__slider-small {
    background: $color-default-primary;
  }
}

@media (max-width: 530px) {
  .view-item__arrow-right,
  .view-item__arrow-left {
    &::before {
      width: 25px;
      height: 25px;
    }
  }
}
