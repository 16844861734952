
 .time-range{
    z-index: 2;
    max-width: 280px;
    width: 100%;
    text-align: center;
    padding: 10px 0 25px 0;
    background: $color-default-primary;
    position: absolute;
    top: 100%;
    left: 50%;
    transform:translate(-50%, 0);
    -webkit-border-radius: 8px;
            border-radius: 8px;
    box-shadow: 0 4px 12px rgba($color-default-sextuple,.25);
    display: none;
    p{
        font-size: 14px;
        color: $color-default-quinary;
        border-bottom: 1px solid $color-default-secondary;
        margin-bottom: 25px;
        padding: 0 0 10px 0;
    }
    // Обновление слайдера
    .ui-slider-horizontal {
        height: 8px;
        background: darken($color-default-primary,10%);
        border: 1px solid darken($color-default-primary,24%);
        box-shadow: 0 1px 0 $color-default-primary, 0 1px 0 $color-default-primary inset;
        clear: both;
        margin: 8px 0;
        border-radius: 6px;
    }
    .ui-slider {
        position: relative;
        text-align: left;
    }
    .ui-slider-horizontal .ui-slider-range {
        top: -1px;
        height: 100%;
    }
    .ui-slider .ui-slider-range {
        position: absolute;
        z-index: 1;
        height: 8px;
        font-size: .7em;
        display: block;
        // border: 1px solid $color-primary;
        // box-shadow: 0 1px 0 $color-primary inset;
        border-radius: 6px;
        // background: $color-primary;
        background-size: 100%;
        // background: $color-primary;
    }
    .ui-slider .ui-slider-handle {
        border-radius: 50%;
        // background: $color-primary;
        background: $color-default-primary;
        background-size: 100%;
        width: 22px;
        height: 22px;
        box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6);
        transition: box-shadow .3s;
    }
    .ui-slider .ui-slider-handle {
        position: absolute;
        z-index: 2;
        width: 22px;
        height: 22px;
        cursor: default;
        border: none;
        cursor: pointer;
    }
    .ui-slider .ui-slider-handle:after {
        content:"";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 50%;
        margin-top: -4px;
        left: 50%;
        margin-left: -4px;
        background: $color-default-quinary;
        // box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
    .ui-slider-horizontal .ui-slider-handle {
        top: -.5em;
        margin-left: -.6em;
    }
    .ui-slider a:focus {
        outline:none;
    }

    .slider-range-time {
      width: 90%;
      margin: 0 auto;
    }


    .c-close{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }
}


