.single {
  padding: 20px 0 150px 0;
}

.single__wrapp {
  width: 100%;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.single__image {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: calc(100% * 450 / 900);
}

.single__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.single__desc {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.single__title {
  font-size: 40px;
  padding: 30px 0 40px 0;
}

.single__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.single__content {
  padding: 55px 0 0 0;
}

.single__content {
  p {
    font-size: 14px;
    line-height: 150%;
  }
  h3 {
    font-size: 18px;
    padding: 40px 0 30px 0;
  }
  ul {
    padding: 15px 0 0 0;
    li {
      position: relative;
      padding: 10px 0 10px 40px;
      font-size: 14px;
      &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        top: 17px;
        left: 20px;
        background: $color-default-tertiary;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
    }
  }
  ol {
    padding-left: 35px;
    li {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 991px) {
}

@media (max-width: 730px) {
}

@media (max-width: 530px) {
  .single__title {
    font-size: 32px;
  }

  .single__content {
    h3 {
      font-size: 18px;
      padding: 50px 0 20px 0;
    }
  }
}

@media (max-width: 430px) {
}

@media (max-width: 380px) {
}
