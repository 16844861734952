// Стили которые нужно будет разложить по полочкам
.table__row_down {
    font-size: 18px;
}
.table__row_down .table__col:nth-child(2) {
    padding: 30px 15px 30px 0;
}
.table__row_down .table__text {
    padding-left: 15px;
}
.table__col_down {
    font-size: 18px;
}
.table__col_down:nth-child(1) {
    padding: 30px 15px 30px 0;
    background: red;
}
.booking__col_mb35 {
    margin-bottom: 35px;
}
.table_down {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
}
.table_down .table__col {
    padding: 30px 0;
}
@media (max-width:530px) {
    .table_down .table__row_down {
        font-size: 14px;
    }
}
.bonus{
    display: none;
}
.personal-data-margin{
    margin-bottom: 15px;
}
.recommendation__info .field .form-group{
    width: 100%;
    min-height: 45px;
}
.order__row .field .form-group{
    width: 100%;
    min-height: 45px;
}
.order__wrapp .field .form-group{
    width: 100%;
    min-height: 45px;
}
.popup__col .field .form-group{
    width: 100%;
    min-height: 45px;
}
.lk-data__block .has-error .help-block {
    color: #dd4b39;
    margin-bottom: 5px;
}
.empty {
    margin: 0px 25px;
}
.display_none{
    display: none !important;
}
.btn-sm-code{padding:12.5px 10px;font-size:12px;line-height:1.5;border-radius:3px;height:45px}
.code-btn{position:absolute;right:0;top:0}
@media (max-width:730px){.code-btn{position:relative;right:auto;top:0}}

.btn.disabled,.btn[disabled],fieldset[disabled] .btn{cursor:not-allowed;-webkit-box-shadow:none;box-shadow:none;opacity:.65}
button[disabled]:after{cursor:not-allowed}

.has-error .form-control{border-color:#a94442;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);box-shadow:inset 0 1px 1px rgba(0,0,0,.075)}
.without_arrow:before{
    padding: 0;
    border: 0;
}
.header__col_first1{
    text-align: center;
    margin: 0 auto;
}
.header__col_first2{
    text-align: right;
    margin-left: auto;
}
.repeat_ajax, .repeat_ajax_recovery{
    display: none;
}
.gratitude_info{
    display: block;
    padding-bottom: 15px;
}
.js_success_promo{
    position: relative;
}
.form-group.has-error1 .help-block {
     margin-bottom: 10px;
 }
.form-group.has-error2 .help-block {
    margin-bottom: 10px;
    margin-top: 0;
}
