.head-catalog {
  display: none;
}

@media (max-width: 1199px) {
  .head-catalog {
    position: relative;
    display: block;
    color: $color-default-primary;
    width: 100vw;
    transition: all 0.3s;
    order: 2;
    .menu-info {
      margin: 0;
      a {
        color: $color-default-primary;
        font-size: 16px;
      }
      .menu-info__items {
        line-height: 1;
        &:first-child {
          padding-bottom: 0;
        }
      }
      .menu-info__address {
        font-size: 10px;
        padding-left: 15px;
        svg {
          width: 8px;
          path {
            fill: $color-default-primary;
          }
        }
      }
    }
  }
  .head-catalog__wrapp {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .head-catalog__list {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;

    overflow-y: auto;
    top: 100%;
    height: calc(100vh - 100px);
    width: 100%;
    position: absolute;
    transition: all 0.3s;
    padding-left: 60px;
    padding-right: 20px;
    > li {
      padding-top: 25px;
    }
    .head-catalog__link {
      font-family: $medium;
      text-transform: uppercase;
    }
    a {
      color: $color-default-quinary;
      display: block;
      background: transparent !important;
    }
  }
  .head-catalog__list-inner {
    margin-top: 15px;
    li {
      margin-bottom: 10px;
      margin-left: 15px;
    }

    .container {
      display: inline;
      line-height: 24px;
    }

    .container:after {
      display: inline-block;
      height: 10px;
      width: 12px;
      content: '';
      margin-left: 5px;
      background: url('./../../images/views/blocks/head-catalog/catalog-arrow.svg')
        center no-repeat;
    }
  }

  body {
    &.js-body-sidebar-menu {
      .head-catalog {
        opacity: 0;
      }
    }
    &.js-body-head-catalog {
      overflow: hidden;
      .head {
        &.sticky {
          .head-catalog {
            transform: translate(0, 0px);
          }
        }
      }
      .head-catalog {
        transition: all 0.3s 0.3s;
        transform: translate(0, -50px);
      }

      .head-catalog__list {
        visibility: inherit;
        opacity: 1;
        transform: translateY(0%);
        pointer-events: auto;
        background: $color-default-primary;
        padding-bottom: 30px;
        height: 100vh;
        padding-bottom: 140px;
        z-index: 400;
      }
    }
  }
}
