@mixin wrapper($padding, $width: 100vw, $max-width: 100vw) {
  padding: 0 $padding;
  width: calc($width - ($padding * 2));
  margin: 0 auto;
  max-width: calc($max-width - ($padding * 2));
}

@mixin default-pseudo-element($width, $height: $width) {
  content: '';
  position: absolute;
  width: $width;
  height: $height;
}

@mixin centering-pseudo-element() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centering-vertical-pseudo-element() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin centering-horizontal-absolut-element() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin triangle(
  $width: 15px,
  $height: 15px,
  $direction: 'down',
  $color: #333333
) {
  @if $direction == 'up' {
    width: 0;
    height: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-bottom: $height solid $color;
  } @else if $direction == 'left' {
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-right: $height solid $color;
  } @else if $direction == 'right' {
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $height solid $color;
  } @else {
    width: 0;
    height: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-top: $height solid $color;
  }
}
