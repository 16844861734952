.gallery-slider {
  padding: 10px 0 80px 0;
  background: $color-default-primary;
}
.gallery-slider__wrapp {
  position: relative;
  padding-top: 30px;
  .container {
    position: relative;
    z-index: 1;
  }
}

.gallery-slider__slider {
  margin: 0 auto;
  max-width: 955px;
}

.gallery-slider__slide {
  padding: 0 15px;
}

.gallery-slider__img {
  width: 100%;
}

@media (max-width: 1170px) {
  .gallery-slider__slider {
    max-width: 855px;
  }
}

@media (max-width: 380px) {
}
