.product{
	padding: 10px;
	transition:0.3s;
	position: relative;
	height: 100%;
	display: block;
	&:hover{
		background: $color-default-primary;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
	}
	&:hover .product__btn{
		background: $color-default-primary;
		transition:0.3s;
	}

	&--more{
		// background: $color-default-primary;
		// box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
		color: lighten($color-default-quinary,28%);
		display: flex;
		
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
	}
}

.product__block-link{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	z-index: 2;
	width: 100%;
	height: 100%;
}
.product__image{
	width: 100%;
	position: relative;
	padding-top: 100%;
	&--primary{
		padding-top: 70%;
	}
}

.product__img{
	width: 100%;
	height: 100%;
	object-fit:cover;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 10px;
}



.product__like{
	position: absolute;
	right: 5px;
	top: 5px; 
	z-index: 5;
	display: block;
	cursor: pointer;
	input[type=checkbox]{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
	path{
		transition:0.3s;
	}
	input[type=checkbox]:checked + svg path{
		fill:#B81E1F;
		stroke:$color-default-primary;
	}
	&:hover {
		path{
			fill:rgba(#B81E1F,0.5);
			stroke:$color-default-primary;
		}
	}
}

.product__color{
	position: absolute;
	bottom: 0px;
	right: 0px;
	font-size: 0;
}

.product__color-link{
	display: inline-block;
}


.product__color-link-r16{
	right: -16px;
	position: relative;
	z-index: 7;
}

.product__footer{
	text-align: center;
	padding-top: 10px;
	&--text-left{
		text-align: left;
	}
}

.product__category{
	font-size: 14px;
	// position: relative;
	// z-index: 5;
	font-family: $light;
	li{
		display: inline-block;
	}
	a{
		color: $color-default-quinary;
	}
}


.product__name{
	display: block;
	padding: 5px 0;
	font-size: 20px;
	color: $color-default-quinary;	
    overflow: hidden;
    height: 60px;

	&--primary{
		font-size: 18px;
	}
}
.product__text{
	padding: 5px 0 15px;
	font-size: 14px;
}



.product__price{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	    -webkit-justify-content: center;
	            justify-content: center;
	font-size: 26px;
	padding-bottom: 10px;
}
.product__price-sale{
	font-size: 14px;
	text-decoration-line: line-through;
	color: rgba(0, 0, 0, 0.5);
	margin-right: 20px;
}





@media (max-width:1199px) {
	.product__price{
		font-size: 24px;
	}
}
@media (max-width:991px) {
	.product__price{
		font-size: 22px;
	}
}

@media (max-width:730px) {
	.product{
		padding: 5px;
	}

	.product__name,
	.product__price{
		font-size: 20px;
	}

	
}

@media (max-width:530px) {
	.product__name,
	.product__price{
		font-size: 18px;
	}
}

@media (max-width:480px) {
	.product {
		&__footer {
			text-align: left;
		}

		&__price {
			justify-content: flex-start;
			font-size: 14px;
			font-family: $medium;
		}
		&__price-sale {
			margin-right: 0;
		}

		&__color {
			display: none;
		}

		&__category {
			font-size: 10px;
			color: $color-default-duodecimal;
		}
		&__name {
			font-size: 14px;
			line-height: 17px;
			padding: 8px 0;
			color: $color-default-duodecimal;
		}
	}
}