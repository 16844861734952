.btn {
  color: $color-default-primary;
  // background: $color-secondary;
  max-width: 285px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  transition: all 0.3s;
  line-height: 50px;
  display: inline-block;
  position: relative;
  text-align: center;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;

  font-family: $regular;
  // &:hover{
  // 	background: darken($color-secondary, 5%);
  // }
  &--primary {
    // background: $color-primary;
    // &:hover{
    // 	background: darken($color-primary, 5%);
    // }
  }
  &--secondary {
    background: darken($color-default-secondary, 15%);
    &:hover {
      background: darken($color-default-secondary, 20%);
    }
  }
  &--tertiary {
    z-index: 5;
    color: $color-default-sextuple;
    // background: rgba($color-secondary, 0.1);
    border: none;
    height: 35px;
    line-height: 35px;
    border-radius: 2px;
    span {
      position: relative;
      display: inline-block;
    }
    svg {
      transition: all 0.3s;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: calc(100% + 5px);
      width: 12px;
      path {
        fill: $color-default-sextuple;
      }
    }
    &:hover {
      background: transparent;
      svg {
        opacity: 1;
      }
    }
  }
  &--quaternary {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    background: darken($color-default-secondary, 15%);
    &:hover {
      background: darken($color-default-secondary, 20%);
    }
  }

  &--quinary {
    display: flex;
    align-items: center;
    padding-left: 45px;
    border-radius: 4px;
    color: $color-default-quinary;
    height: 36px;
    background: url('./../../images/views/blocks/c-button/question.svg') 10px
      center no-repeat;
    &:hover {
      background: url('./../../images/views/blocks/c-button/question.svg') 10px
        center no-repeat;
    }
  }

  &--style {
    &-arrow {
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: calc(50% - 2px);
        left: 20px;
        transform: translate(0%, -50%) rotate(135deg);
        border: solid $color-default-primary;
        border-width: 1px 1px 0 0;
      }
    }
  }

  &--border {
    &-primary {
      background: transparent;
      border: 1px solid $color-default-primary;
      color: $color-default-primary;
    }
  }

  &--width {
    &-primary {
      max-width: 360px;
    }
    &-secondary {
      max-width: 240px;
    }
    &-tertiary {
      max-width: 140px;
    }
    &-quaternary {
      max-width: 200px;
    }
    &-full {
      max-width: 100%;
    }
  }

  &--height {
    &-primary {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
    &-secondary {
      height: 45px;
      line-height: 45px;
    }
    &-tertiary {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }

  &--sales {
    width: 64px;
    height: 30px;
    border-radius: 4px;
    background-image: url('./../../images/views/blocks/c-button/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url('./../../images/views/blocks/c-button/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &-history {
    background: #378536;
    border-radius: 4px;
    color: white;
    min-width: 120px;
    margin-right: 10px;
    &:hover {
      background: rgba(#378536, 0.2);
      color: #828282;
    }
    &--disabled {
      background: rgba(#378536, 0.2);
      color: #828282;
    }

    &--width {
      min-width: 165px;
    }
  }
}
