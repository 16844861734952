@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder           { @content; }
	&::-moz-placeholder          { @content; }
	&:-ms-input-placeholder      { @content; }
}



@mixin width_height($size){
	width: $size;
	height: $size;
}