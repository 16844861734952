.burger{
	display: none;
    height: 100%;
    align-items: center;
    justify-content: center;
    &.active{
    	.burger__box{
    		span{
    			top: 50%;
    			left: 50%;
    		}
    		span:first-child{
    			transform:translate(-50%,-50%) rotate(-45deg);
    			
    		}
    		span:last-child{
    			width: 26px;
    			transform:translate(-50%,-50%) rotate(45deg);
    			margin: 0;
    		}
    	}
    }
}

.burger__box {
	height: 8px;
	width: 26px;
	position: relative;
	span{
		left: 0;
		transition:0.3s;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $color-default-quinary;
		border-radius: 10px;
		
		display: block;
		&:first-child{
			top: 0;
		}
		&:last-child{
			width: 20px;
			bottom: 0;
		}
	}
}

@media (max-width:991px) {
	.burger{
		 display: flex;
	}
	
}

@media (max-width:730px) {

}

@media (max-width:530px) {


}