// #414141
// fonts


$bold: 'Museo-Cyrl-700', sans-serif;
$medium: 'Museo-Cyrl-500', sans-serif;
$regular: 'Museo-Cyrl-300', sans-serif;
$light: 'Museo-Cyrl-300', sans-serif;

// $icomoon: 'icomoon', sans-serif;

// colors

$color-default-primary:#FFFFFF; // БЕЛЫЙ
$color-default-secondary:#F8F8F8; // СЕРЫЙ BG
$color-default-tertiary:#E3E3E3; // ЛИНИИ STROKE
$color-default-quaternary:#333333; // TEXT
$color-default-quinary:#525151; // TEXT GREY
$color-default-sextuple:#000000; // DARK
$color-default-septenary:#ED4545; // КРАСНЫЙ
$color-default-octonary:#FFBD74; // orange
$color-default-nonary:#F6F6F6; // gray
$color-default-decimal:#FFFFE7;
$color-default-duodecimal:#544649;

$color-border: #e1e1e1;
$color-highlight: #EB5757;
$color-highlight-primary: #FF4763;

$color-rare:#525151;
$color-rare-primary:#C63027; // RED

$color-danger: #E93737; 
$color-success: #14D2B8;



// GRID
$grid-breakpoints: (
        xs: 0,
        sm: 530px,
        md: 730px,
        lg: 991px,
        xl: 1199px,
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 480px,
        md: 690px,
        lg: 970px,
        xl: 1170px,
) !default;



$spacer: 10px !default;
$spacers: () !default;


$spacers: map-merge(
  (
    0: ($spacer * 0),
    1: ($spacer * 1.5),
    2: ($spacer * 1),
    3: ($spacer * .5),
    4: ($spacer * 2),
    5: ($spacer * 3),
    6: ($spacer * 5),
    7: ($spacer * 2.5),
  ),
  $spacers 
);



// DISPLAYS
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
