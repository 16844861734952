.logo{
	display: inline-block;
	vertical-align: middle;
	p{
		font-family: $light;
		font-size: 12px;
	}
}
.logo__img{
	max-height: 76px;
	max-width: 280px;
	width: inherit;
}


@media (max-width:1199px) {
	.logo__img{
		max-height: 40px;
		max-width: 160px;
	}
}
@media (max-width:730px) {
	
}

@media (max-width:380px) {
	.logo{
		p{
			font-size: 9px;
		}
	}
}