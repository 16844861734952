html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 1.25;
  font-size: 16px;
}

body {
  font-family: $light;
  color: $color-default-quinary;
  &.overlay {
    overflow: hidden;
    .wrapper {
      &::before {
        opacity: 1;
        visibility: inherit;
        pointer-events: auto;
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  text-decoration: 0;
  box-sizing: border-box;
}

#wrapper {
  width: 100%;
  min-width: 1170px + 20px;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.wrapper {
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba($color-default-sextuple, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 450;
    opacity: 0;
    transition: all 0.3s;
    visibility: hidden;
    pointer-events: none;
  }
}

#footer {
  width: 100%;
  min-width: 1170px + 20px;
  position: relative;
  overflow: hidden;
}

.container {
  position: relative;
  width: 1170px;
  margin: 0 auto;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

@media (max-width: 1850px) {
  #wrapper {
  }
}

@media (max-width: 1199px) {
  body.overlay .wrapper:before {
    z-index: 0;
  }
}

@media (max-width: 1215px) {
  #wrapper {
    min-width: 100%;
  }

  #footer {
    min-width: 100%;
  }

  .container {
    width: 970px;
  }
}

@media (max-width: 991px) {
  #wrapper {
    min-width: 100%;
  }

  .container {
    width: 85%;
  }
}
@media (max-width: 730px) {
  .container {
    width: 480px;
  }
}
@media (max-width: 530px) {
  .container {
    max-width: 480px;
    width: calc(100% - 20px);
  }
}
