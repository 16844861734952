.alert{

	padding: 6px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 15px;
    &-danger{
    	color: darken($color-danger,15%);
		background: rgba($color-danger,0.4);
	}
	&-success{
		color: darken($color-success,15%);
		background: rgba($color-success,0.4);
	}
	
} 