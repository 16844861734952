.c-sale {
	display: flex;
	align-items: center;
	padding: 9px;
	padding-right: 60px;
	width: 100%;
	border: 1px dashed $color-border;
	background-color: $color-default-primary;

	text-align: left;
	position: relative;

	&__image {
		height: 214px;
		width: 214px;
		min-width: 214px;
		background-color: $color-default-nonary;
		margin-right: 33px;
		background-size: cover;
	}

	&__content {
		padding: 20px 0;
	}

	&__title {
		font-size: 22px;
		line-height: 30px;
		text-transform: uppercase;
		font-weight: 500;
		margin-bottom: 15px;
	}

	&__text {
		font-size: 18px;
		line-height: 30px;
		margin-bottom: 17px;
		font-weight: 500;
	}

	&__term {
		font-size: 14px;
		line-height: 30px;
		color: $color-default-quinary;
	}

	&__link {
		position: absolute;
		bottom: 23px;
		right: 19px;
	}
}

@media (min-width:1199px) {
	.c-sale {
		width: 820px;
	}
}

@media (max-width:1199px) {
	.c-sale {
		width: 100%;
	}
}

@media (max-width:730px) {
	.c-sale {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 30px 20px;

		&__image {
			margin-bottom: 30px;
		}
	}
}

@media (max-width:480px) {
	}