.reviews__wrapp {
  padding: 0 15px;
  margin-top: 60px;
  margin-bottom: 110px;
  display: flex;
  flex-direction: column;
  gap: 86px;

  @media (max-width: 580px) {
    margin-top: 50px;
    margin-bottom: 90px;
  }
}

.reviews__title {
  font-size: clamp(25px, 3.5vw, 50px);
  position: relative;
  text-align: left;
  width: fit-content;
  &:after {
    content: '';
    width: 170px;
    height: 170px;
    background: url(./../../images/icons/icon-quotes.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: -1;
    top: -30px;
    right: 0;

    @media (max-width: 580px) {
      content: none;
    }
  }
}

.reviews-slider {
  width: calc(100% - 106px);

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    max-width: 600px;
  }

  @media (max-width: 730px) {
    max-width: 450px;
  }
}

.reviews-slider__slide {
  padding-left: 50px;

  @media (max-width: 1199px) {
    padding-left: 20px;
  }

  @media (max-width: 991px) {
    padding-left: 0px;
  }
}

.reviews__block {
  display: flex;
  flex-direction: column;
  padding-top: 62px;

  position: relative;
  transition: 0.5s;
  pointer-events: all;

  &.hide {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    padding-top: 0;
    transition: 0s;
  }
}

.reviews__load-more {
  border-radius: 8px;
  background: rgba(196, 83, 118, 0.1);
  width: 240px;
  text-align: center;
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 7.5px 0;
  display: block;
  border: none;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    background: rgba(196, 83, 118, 0.2);
  }
}

.reviews__block__inner-container {
  padding: 0 25px 35px 30px;
  border-radius: 12px;
  height: 410px;
  display: flex;
  flex-direction: column;
  background: rgba(232, 232, 230, 0.4);

  @media (max-width: 1215px) {
    height: 500px;
  }

  @media (max-width: 991px) {
    height: 340px;
  }

  @media (max-width: 730px) {
    height: 410px;
  }

  @media (max-width: 500px) {
    height: 450px;
  }
}

.reviews__photo {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  position: absolute;
  @include centering-horizontal-absolut-element();
  border: 15px solid white;
  border-radius: 50%;
  top: 0;
  overflow: hidden;
}

.review__title {
  margin-top: 131px;

  color: #c45376;

  /* H3 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
  margin-bottom: 20px;
}

.reviews__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  @media (max-width: 991px) {
    gap: 25px;
    grid-template-columns: 1fr;
  }
}

.reviews__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.reviews__name {
  font-size: 18px;
  padding-bottom: 15px;
  display: block;
  margin-top: auto;
}

.reviews__text {
  color: #525151;

  /* Text */
  //font-family: Futura PT;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 138.889% */
}

.reviews__pack {
  @media (max-width: 1199px) {
    width: 100%;
  }
}
