.delivery{
	
}

.delivery__box{
	
}

.delivery__radio{
	
}

@media (max-width:991px) {
	.delivery{
		padding-top: 20px;
	}
	
	.delivery__radio{
		display: inline-block;
		margin-right: 30px;
	}
}


@media (max-width:730px) {

}

@media (max-width:530px) {


}

@media (max-width:380px) {

}