.sidebar{
	position: relative;
	width: 315px;
	overflow: hidden;
	font-size: 16px;
	//border: 1px solid rgba($color-default-quinary, 0.2);
	top: -88px; 
	
	&--primary{
		top: 0;
		width: 275px;
		border: none;
	}
	&--secondary{
		top: 0;
		width: 225px;
		border: none;
	}
}

.sidebar__wrapp{
	padding-top: 15px;
}

.sidebar__box {
	background: $color-default-primary;
	border: 1px solid rgba($color-default-quinary, 0.2);
	padding: 15px 20px 0 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.sidebar__box-list{
	padding: 20px 0 20px 0px;
}


.sidebar__product-list{

}
.sidebar__product-item{
	padding: 5px 0;
}




.sidebar__box_mobile-filter{
	display: none;
}

// mobile

@media (max-width:991px) {
	.sidebar{
		width: 230px;
		padding:0 15px;
		font-size: 16px;
		&--primary{
			width: 100%;
			-webkit-order: 2;
			    -ms-order: 2;
			        order: 2;
		}
		&--secondary{
			width: 100%;
		}
	}
}

@media (max-width:730px) {
	.sidebar{
		display: block;
		width: 100%;
		padding: 0px 5px;
		top: 0;
		border: none;
		overflow: visible;
	}
	.sidebar__box-list {
   	 padding: 20px 0 20px 0px;
	}

	.sidebar__box_mobile-filter{
		display: block;
	}
	.sidebar__box-product-col{
		width: 50%;
		padding: 10px 5px;
	}
}

@media (max-width:530px) {


}


@media (max-width:380px) {
	.sidebar__box-product-col{
		width: 100%;
		padding: 10px 5px;
	}
	
	.sidebar-mobile {
		&__content {
			&-bottom {
				padding: 30px 20px;
			}

			&-info {
				.menu-info__number {
					font-size: 14px;
				}

				.sidebar-mobile__list {
					span {
						font-size: 14px;
						font-weight: 700;
					}
					.select-option__text {
						font-size: 14px;
					}
				}
			}
		}
	}
}