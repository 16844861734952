.sorting{
	display: none;
	// padding: 0 10px;
}

.sorting__wrapp{
	display: flex;
	margin: 0 -5px;
}

.sorting__col{
	width: 50%;
	font-size: 16px;
	color: $color-default-quinary;
	padding: 0 5px;
}


.sorting__box-list-name{
	position: relative;
	
	background: lighten($color-default-tertiary,7%);
	width: 100%;
	height: 34px;
	line-height: 34px;
	font-size: 16px;
	color: $color-default-quinary;
	text-align: center;
	 & svg{
		position: absolute;
		top: 50%;
		transform:translate(0,-50%);
		left: 15px;
	 }

	 &.active{
	 	// background: $color-primary;
	 	color: #FFF;
	 	svg path{
	 		fill:white;
	 	}
	}
}

.sorting__link{
	color: $color-default-quinary;
}




@media (max-width:991px) {

}


@media (max-width:730px) {
	.sorting{
		display: block;
	}

	.sorting__wrapp{
		padding-bottom: 20px;
	}
}

@media (max-width:530px) {


}

@media (max-width:380px) {

}