.header {
  width: 100%;
  color: $color-default-primary;
  position: relative;
  // @include media-breakpoint-down(lg) {
  // 	padding-top:50px;
  // }
  @include media-breakpoint-down(md) {
    &::before {
      content: '';
      width: 100vw;
      height: 160px;
      top: 0;
      position: absolute;
      border-radius: 0;
      opacity: 1;
    }
  }
}

.header__wrapp {
  padding: 25px 0 50px 0;
}

.header__pack {
  position: relative;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 410px;
  z-index: 2;
  // height: calc(100vh - 126px);
  max-width: 1060px;
  padding: 15px;
  margin: 0 auto;
}

.header__title {
  font-size: 42px;
  text-transform: uppercase;
}

.header__text {
  font-size: 24px;
  padding: 20px 0 35px 0;
}

.header__virtues {
  max-width: 975px;
  width: 100%;
  margin: 0 auto;
}

.header__virtues-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header__virtues-col {
  width: 25%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: $color-default-quinary;
  svg {
    display: block;
    width: 40px;
    height: 40px;
  }

  img {
    width: 40px;
    height: 40px;
  }
  p {
    padding-left: 15px;
    width: calc(100% - 26px);
    font-size: 14px;
    text-transform: uppercase;
  }
}

@media (max-width: 1199px) {
  .header__virtues-col {
    width: 50%;
    padding-bottom: 45px;
    p {
      padding-left: 10px;
    }
  }
}

@media (max-width: 730px) {
  .header__virtues-col {
    padding: 0 10px 30px 10px;
  }

  .js-body-head-catalog .header {
    z-index: 0;
  }

  .header__pack {
    height: 210px;
  }

  .header__wrapp {
    padding-top: 0;
  }
}
@media (max-width: 480px) {
  .header__title {
    font-size: 32px;
    text-transform: inherit;
  }

  .header__text {
    font-size: 20px;
    padding: 20px 0 35px 0;
  }
}

@media (max-width: 430px) {
}

@media (max-width: 380px) {
  .header__virtues-col {
    width: 100%;
    &:nth-child(2) {
      width: 100%;
    }
    &:last-child {
      width: 100%;
    }
  }
}
