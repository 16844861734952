.lk-sections{
	margin:0 -15px;
}

.lk-sections__tab{
	padding:0 15px;
	font-size: 20px;
	display: inline-block;
	margin-bottom: 25px;
	a{
		cursor: pointer; 
		color: $color-default-quinary;
	}
	&.ui-tabs-active{
		a{
			font-family: $medium;
		}
	}
}

.lk-sections__exit{
	display: flex;
		
	&:hover{
		transition:0.3s;
		font-weight: 500;
	}
	img{
		padding-right: 15px;
	}
}





@media (max-width:380px) {
	.lk-sections__tab{
		font-size: 18px;
	}
}