%h-booking-common-primary{
	position: relative;
	display: flex;
}

.booking{
	display: none;
	margin: -10px 0;
	padding: 15px;
}
.booking__item{
	display: flex;
	padding: 10px 0;
}
.booking__col{
	position: relative;
	&--first{
		padding-top: 15px;
		width: 40px;
	}
	&--second{
		width: calc(100% - 40px);
	}
}
.booking__count{
	font-family: $light;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
}

.booking__head{
	@extend %h-booking-common-primary;
    align-items: center;
    padding-right: 35px;
}
.booking__content{
	padding-top: 18px;
	margin: -5px 0;
}
.booking__footer{
	text-align: right;
}

.booking_line{
	width: 100%;
	height: 1px;
	background: rgba($color-default-quaternary, 0.2);
	display: block;
	margin: 15px 0;
}

.booking__icon{
	width: 25px;
	height: 25px;
	position: relative;
	margin-right: 10px;
	svg{
		width: 20px;
		position: absolute;
		top: 50%; left: 50%; transform:translate(-50%,-50%);
		// path{
		// 	fill:$color-primary;
		// }
	}
}
.booking__text{
	font-family: $light;
	font-size: 18px;
	color: $color-default-sextuple;
	&--size-primary{
		font-size: 18px;
	}
	&--weight-medium{
		font-family: $medium;
	}

	&--uppercase{
		text-transform: uppercase;
	}

	&--name{
		padding-left: 18px;
		width: calc(100% - 52px);
	}
}

.booking__content-item{
	padding: 5px 0;
	@extend %h-booking-common-primary;
    align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.booking__content-count{
	width: 100px;
}

.booking__img{
	width: 52px;
	height: 52px;
	object-fit:cover;
}


@media (max-width:730px) {
	.booking{
		display: block;
	}
}
@media (max-width:530px) {
	.booking__col{
		&--first{
			padding-top: 15px;
			width: 20px;
		}
		&--second{
			width: calc(100% - 20px);
		}
	}
	.booking__text{
		font-size: 14px;
		&--size-primary{
			font-size: 16px;
		}
	}

}
